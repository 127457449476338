import { findWeekIdsInRange, getFirstDate, getLastDate } from "utils/Date";
import moment from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useMemo, useState } from "react";
import { Spin } from "antd";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import { useFetch } from "hooks/useFetch";
import { useSelector } from "react-redux";
import { getUserInfo } from "redux/selectors";

const EngagementWeekCalendar = ({ weeksThisMonth }) => {
  const sDate = getFirstDate(moment());
  const eDate = getLastDate(moment());
  const userInfo = useSelector(getUserInfo);
  const weekList = findWeekIdsInRange([sDate, eDate]);
  const [payload, setPayload] = useState({
    startDate: new Date(sDate),
    endDate: new Date(eDate),
    employeeIds: [],
    engagementIds: [],
    weekId: weekList,
  });

  const { key: resourceKey, url: resourceUrl } =
    queryKeys.getResourePlanningList(payload?.weekId?.join(","));

  const {
    data: resourceDataRes,
    isLoading: isResourceLoading,
    refetch,
  } = useFetch(
    resourceKey,
    resourceUrl,
    {
      enabled: payload?.weekId?.length > 0,
    },
    payload
  );

  const myResourceData = resourceDataRes?.response?.response?.filter((res) => {
    return res.userId === userInfo.userid;
  });

  const myBookedDates = myResourceData?.reduce((acc, res) => {
    const startDate = moment(res.actualBookedStartDate, "YYYY-MM-DD");
    const endDate = moment(res.actualBookedEndDate, "YYYY-MM-DD");

    const dates = [];
    let current = moment(startDate);
    while (current.isBefore(endDate) || current.isSame(endDate, "day")) {
      dates.push({
        date: current.format("YYYY-MM-DD"),
        ...res,
      });
      current.add(1, "day");
    }
    return [...acc, ...dates];
  }, []);
  
  const events = useMemo(() => {
    const eventsByDate = {};
  
    myBookedDates?.forEach((event) => {
      const startDate = moment(event.bookedStartDate).tz(moment.tz.guess());
      const endDate = moment(event.bookedEndDate).tz(moment.tz.guess());
  
      // Create events for each day between start and end dates
      for (
        let date = startDate.clone();
        date.isBefore(endDate.clone().add(1, "days"));
        date.add(1, "days")
      ) {
        const dateKey = date.format('YYYY-MM-DD');
  
        if (!eventsByDate[dateKey]) {
          eventsByDate[dateKey] = [];
        }
  
        eventsByDate[dateKey].push(event);
      }
    });
  
    const dateRange = [];
  
    // Distribute events across available slots
    Object.entries(eventsByDate).forEach(([date, eventsForDate]) => {
      if (eventsForDate.length === 1) {
        // Only one event, set time from 8:30 AM to 5:30 PM
        const event = eventsForDate[0];
        const start = moment(date).set({ hour: 8, minute: 30 });
        const end = moment(date).set({ hour: 17, minute: 30 });
  
        dateRange.push({
          title: `${event.employeeName}`,
          start: start.toISOString(),
          end: end.toISOString(),
          status: 4,
          ...event,
        });
      } else {
        // More than one event, distribute into 4-hour slots
        const baseStartHour = 8.5; // Start at 8:30 AM
        const timeIncrement = 4; // Each event gets a 4-hour slot
  
        eventsForDate.forEach((event, index) => {
          const startHour = baseStartHour + index * timeIncrement; // Increment start time by 4 hours for each event
          const start = moment(date).set({ hour: Math.floor(startHour), minute: (startHour % 1) * 60 });
          const end = start.clone().add(4, 'hours'); // Each event lasts for 4 hours
  
          dateRange.push({
            title: `${event.employeeName}`,
            start: start.toISOString(),
            end: end.toISOString(),
            status: 4,
            ...event,
          });
        });
      }
    });
  
    return dateRange;
  }, [myBookedDates]);
  
  const { getAllClientsList } = queryKeys;

  const { key, url } = getAllClientsList;

  const {
    data: clientsDataRes,
    fetchNextPage,
    isLoading: loading,
    hasNextPage,
  } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
  });

  const clients = useMemo(() => {
    return clientsDataRes?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [clientsDataRes]);

  const clientOptions = clients
    ?.filter((client) => client?.bdo_id !== "NULL")
    ?.map((client) => ({
      label: client?.registeredName,
      value: client?.clientId,
    }));

  const getEventColor = (status) => {
    switch (status) {
      case "Un Assigned":
        return "#FBE2CD"; // Orange
      case "Assigned":
        return "#E2FBCD"; // Light Blue
      case 6:
        return "#FFCCCB"; // Light Red
      default:
        return "#ADD8E6"; // Default to white
    }
  };

  const getEventBorderColor = (status) => {
    switch (status) {
      case "Un Assigned":
        return "#F18D38";
      case 6:
        return "#38CDF1";
      case "Assigned":
        return "#78F138";
      default:
        return "#F18D38";
    }
  };

  const textStyle = {
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Show ellipsis for overflow
  };

  const handleDatesSet = (arg) => {
    const startOfWeek = arg.start; // Start of the week (Date object)
    const endOfWeek = arg.end; // End of the week (Date object)
    const sDate = getFirstDate(moment(arg.start));
    const eDate = getLastDate(moment(arg.end));

    console.log(`Start of the week: ${startOfWeek}`);
    console.log(`End of the week: ${endOfWeek}`);
    const weeks = findWeekIdsInRange([sDate, eDate]);

    // You can format these dates as needed
    const formattedStart = startOfWeek.toISOString().split("T")[0];
    const formattedEnd = endOfWeek.toISOString().split("T")[0];

    setPayload({
      startDate: formattedStart,
      endDate: formattedEnd,
      employeeIds: [],
      engagementIds: [],
      weekId: weeks,
    });

    // You can store these formatted dates in state or use them as needed
    console.log(`Formatted Start: ${formattedStart}`);
    console.log(`Formatted End: ${formattedEnd}`);
  };

  return (
    <>
      <Spin spinning={isResourceLoading}>
        <FullCalendar
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          height="auto"
          contentHeight="auto"
          initialView="timeGridWeek" // Set the initial view to week
          events={events} // Use your events array
          datesSet={handleDatesSet}
          headerToolbar={{
            left: "prev,next", // Navigation buttons
            center: "title", // Title in the center
            right: "timeGridWeek,dayGridMonth", // Week and Month views on the right
          }}
          editable={false}
          selectable={true}
          allDaySlot={false} // Disable the all-day slot
          eventOverlap={false} // Allow events to overlap
          slotEventOverlap={true} // Prevent events from stacking in the same slot
          eventLimit={3} // Limit the number of events shown in a slot
          eventLimitClick={"popover"}
          eventContent={(info) => {
            const backgroundColor = getEventColor(
              info.event.extendedProps.status
            );
            const borderColor = getEventBorderColor(
              info.event.extendedProps.status
            );

            const startTime = info.event.start
              ? moment(info.event.start).format("hh:mm A")
              : "N/A";
            const endTime = info.event.end
              ? moment(info.event.end).format("hh:mm A")
              : "N/A";

            const clientName = clientOptions?.find(
              //? will give result only after submitting Engagement
              (client) => client?.value === info.event.extendedProps?.clientId
            )?.label;

            return (
              <div
                style={{
                  padding: "5px",
                  background: backgroundColor,
                  border: "none",
                  outline: "none",
                  color: "#000",
                  border: `1px solid ${borderColor}`,
                  height: "100%",
                  minWidth: "auto",
                  width: "100%", // Full width
                  boxSizing: "border-box", // Ensure padding is included in width
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <>
                  <span style={textStyle}>{info.event.title}</span>
                  <span style={textStyle}>Engagement:</span>
                  <span style={textStyle}>  
                    {info.event.extendedProps?.engagementName ?? "-"}
                  </span>
                  <span style={textStyle}>Client:</span>
                  <span style={textStyle}> {clientName ?? "-"}</span>
                  <p style={textStyle}>
                    {startTime} - {endTime}
                  </p>
                </>
              </div>
            );
          }}
        />
      </Spin>
    </>
  );
};

export default EngagementWeekCalendar;
