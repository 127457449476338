import React, { createContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Radio } from "antd";
import COLORS from "common/Colors";
import { useSelector } from "react-redux";
import {
  getClientid,
  getMenuList,
  getRoleList,
  getUserInfo,
  getUserList,
  getUserRoleId,
} from "redux/selectors";
import { useFetch } from "hooks/useFetch";
import queryKeys from "common/queryKey";
import {
  addMonthinDate,
  getFirstDate,
  getKenyanDateTime,
  getLastDate,
  momentDate,
} from "utils/Date";
import LeaveWeekCalendar from "./LeaveWeekCalendar";

const LEAVE = "leave";
const ENGAGEMENT = "engagement";

export const CalendarContext = createContext();

const WeekCalendarView = () => {
  const [calendarType, setCalendarType] = useState(ENGAGEMENT);
  const [tabId, setTabId] = useState("Mine");
  const [miniCalendarCurrentDate, setMiniCalendarCurrentDate] = useState(
    getKenyanDateTime()
  );

  const menuList = useSelector(getMenuList);
  const userRoleId = useSelector(getUserRoleId);
  const userList = useSelector(getUserList);
  const userInfo = useSelector(getUserInfo);
  const clientId = useSelector(getClientid);
  const roleList = useSelector(getRoleList);

  let Menulist = menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  Menulist = Array.isArray(Menulist) ? Menulist : [];

  const leaveTabs = Menulist?.filter(
    (item) =>
      item.key === "mycalendar" ||
      item.key === "teamcalendar" ||
      item.key === "allcalendar"
  );

  const engagementTabs = Menulist?.filter(
    (item) =>
      item.key === "myengagementcalendar" ||
      item.key === "teamengagementcalendar" ||
      item.key === "allengagementcalendar"
  );

  const leaveAccess = leaveTabs?.some((item) => item.permission.view);

  const engagementAccess = engagementTabs?.some((item) => item.permission.view);

  useEffect(() => {
    if (leaveAccess) {
      setCalendarType(LEAVE);
    } else if (engagementAccess) {
      setCalendarType(ENGAGEMENT);
    }
  }, [leaveAccess, engagementAccess]);

  const currentActiveAccess = leaveAccess ? (
    <div className="fw-bold">LEAVE</div>
  ) : (
    <div className="fw-bold">ENGAGEMENT</div>
  );

  const previousMonth = () =>
    setMiniCalendarCurrentDate(
      momentDate(addMonthinDate(miniCalendarCurrentDate, "-1"))
    );

  const nextMonth = () =>
    setMiniCalendarCurrentDate(
      momentDate(addMonthinDate(miniCalendarCurrentDate, "1"))
    );

    

  const currentUser = userList?.find(
    (item) => item?.userid === userInfo.userid
  );

  const { key, url } = queryKeys.getHolidayList(clientId);

  const { data: holidayDataRes, refetch } = useFetch(
    key,
    url,
    {
      enabled: !!clientId,
    },
    {
      start_date: getFirstDate(miniCalendarCurrentDate),
      end_date: getLastDate(miniCalendarCurrentDate),
    }
  );

  useEffect(()=>{
    refetch();
  },[miniCalendarCurrentDate])

  const baselocationid = currentUser?.baselocationid;

  const holidayList = holidayDataRes?.response?.filter((item) =>
    item?.location_id?.includes(baselocationid)
  );

  const filterrole = roleList?.find((item) => item?.roleId === userRoleId);
  const filterlocationid = filterrole?.locationId;
  const allHolidayList = holidayDataRes?.response?.filter((item) =>
    item.location_id.some((id) => filterlocationid?.includes(id))
  );

  const radioCalendarSelect = (
    <Radio.Group
      size="middle"
      buttonStyle="solid"
      value={calendarType}
      onChange={(e) => setCalendarType(e.target.value)}
    >
      <Radio.Button value={LEAVE}>Leave</Radio.Button>
      <Radio.Button value={ENGAGEMENT}>Engagement</Radio.Button>
    </Radio.Group>
  );

  return (
    <CalendarContext.Provider
      value={{
        tabBarExtraContent: {
          right:
            leaveAccess && engagementAccess
              ? radioCalendarSelect
              : currentActiveAccess,
        },
        miniCalendarCurrentDate: miniCalendarCurrentDate,
        holidayList: holidayList,
        allHolidayList: allHolidayList,
        previousMonth: previousMonth,
        nextMonth: nextMonth,
      }}
    >
      <StyledCalendar>
        {calendarType === LEAVE && (
          <LeaveWeekCalendar tabId={tabId} setTabId={setTabId} />
        )}
        {/* {calendarType === ENGAGEMENT && (
          <EngagementCalendar tabId={tabId} setTabId={setTabId} />
        )} */}
      </StyledCalendar>
    </CalendarContext.Provider>
  );
};

export default WeekCalendarView;

export const StyledCalendar = styled.div`
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: white;
    background: ${COLORS.SUCCESS};
    border-color: ${COLORS.SUCCESS};
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: white;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 999px 0 0 999px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0 999px 999px 0;
  }
`;
