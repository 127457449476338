import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import Active from "./Active";
import Pending from "./Pending";
import Deleted from "./Deleted";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TabsList from "molecules/TabsList";
import Button from "atoms/Button";
import { ButtonFilled } from "reusableComponent/UIButtons";
import FileImport from "./FileImport";
import { CALL_API_UPDATED } from "common/API";
import queryKeys from "common/queryKey";
import { useScrollFetch2 } from "hooks/useScrollFetch2";
import { useScrollFetch } from "hooks/useScrollFetch";
import { useSelector } from "react-redux";
import { getFilters } from "redux/selectors";

const Client = () => {
  const [clientList, setClientList] = useState([]);
  const [showLoader, toggleLoader] = useState(false);
  const [lastEvalKey, setLastEvalKey] = useState(0);
  const [statusType, setStatusType] = useState("Active");
  const filters = useSelector(getFilters);
 
  // const getClistData = async () => {
  //   toggleLoader(true);
  //   const response = await CALL_API_UPDATED(
  //     `/insyts/clients?LastEvaluatedKey=${lastEvalKey}&status=${statusType}`,
  //     "get"
  //   );
  //   if (response.data) {
  //     toggleLoader(false);
  //     setClientList(response.data);
  //   }

  //   if ("LastEvaluatedKey" in response.data) {
  //     setLastEvalKey(response.LastEvaluatedKey);
  //   }
  // };

  const queryParams = new URLSearchParams();

  const { getClientsList } = queryKeys;

  const { key, url } = getClientsList(filters?.client?.managingoffice ?? []);

  const { data, lastElementRef, isLoading, refetch, isReloading  } = useScrollFetch(
    key,
    {
      endPoint: url,
      queryParam: "LastEvaluatedKey",
      extra: {
        ManagingOffice: filters?.client?.managingoffice ? filters?.client?.managingoffice : []
      }
    },
   
  );

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.data === undefined) return acc;
        return [...acc, ...page?.data];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  const totalFilteredClientCount = data?.pages?.[0]?.count;

  return (
    <ClientWrap>
      <div className="title">
        <div className="heading">Client</div>
        
        <div className="d-flex align-items-center gap-1 flex-start">
        {/* <FileImport getClistData={getClistData}/> */}
          {/* <ButtonFilled 
            // onClick={() => {
            //   history.push("/add-new-client"); //? create a route for this page
            // }}
          >
            Add New
          </ButtonFilled> */}
        </div>
      </div>
      <Active
        // getClistData={getClientsList}
        clientList={dataList}
        showLoader={isLoading}
        loader={isReloading}
        lastElementRef={lastElementRef}
        totalFilteredClientCount={totalFilteredClientCount}
      />
    </ClientWrap>
  );
};

export default Client;

const ClientWrap = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 0.5rem 0; */

    .heading {
      font-weight: 600;
      font-size: 18px;
    }
    
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  /* .ant-btn-default {
    background-color: #c2fae5;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    color: #000000;
    font-weight: 500;
  } */
  .ant-tabs-tab {
    padding: 5px 0 5px 0 !important;
  }
  .ant-tabs-nav {
    background: #f3fefa;
  }
  @media (max-width:  320px){
      .title{
        flex-direction: column;
      }
    }
`;
