import React, { useState, useEffect } from "react";
import { message, Button, Switch, Checkbox } from "antd";
import { Form } from "antd";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { connect, useSelector } from "react-redux";
import { setBreakTypesList, setLeaveCategoryList } from "redux/actions";
import { bindActionCreators } from "redux";
import {
  getLocations,
  getClientid,
  getAllDepartmentList,
  getLeaveCategoryList,
  getUserInfo,
  getUserList,
} from "redux/selectors";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import StepsProgress from "./StepsProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SelectTable from "molecules/SelectTable";
import { getAddLeaveJson } from "JsonFormat/LeaveJson";
import InReview from "molecules/InReview";
import FormModal from "./FormModal";
import LeaveList from "./LeaveList";

const AddLeaves = ({
  closeModal,
  ClientId,
  saveLeaveCategoryList,
  departmentList,
  locationList,
  getLeaveList,
  leaveList
}) => {
  const [showLoader, toggleLoader] = useState(false);
  const [form] = Form.useForm();

  const userInfo = useSelector(getUserInfo)
  const userList = useSelector(getUserList)
  const filterUser = userList?.find((val) => val?.userid === userInfo?.userid)
  const locationBasedOnCountry = locationList?.filter((val) => filterUser?.managingoffice.includes(val?.country)) ?? []

  const fetchMasterData = async (fetch_master_data) => {
    const { code, leaveCategories } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveLeaveCategoryList(leaveCategories);
      }
    }
  };

  const [data, setData] = useState({});
  const { AddLeaveForm } = getAddLeaveJson(data, leaveList);
  const [step, setStep] = useState(0);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectAllDepartmentChecked, setSelectAllDepartmentChecked] =
    useState(false);

  useEffect(() => {
    setSelectAllDepartmentChecked(
      selectDepartmentId?.length === departmentList?.length
    );
    setSelectAllChecked(selectedLocationId?.length === locationList?.length);
  }, [selectDepartmentId, departmentList, selectedLocationId, locationList]);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitForm = (e) => {
    setStep(1);
  };

  const stepdata = [
    { id: 1, title: "Basic" },
    { id: 2, title: "Department" },
    { id: 3, title: "Location" },
    { id: 4, title: "Review" },
  ];

  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllDepartmentChecked ||
              selectDepartmentId.includes(department_id)
            }
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
  };

  const handleSelectAllDepartment = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentChecked(checked);
    const allDepartmentIds = departmentList.map((item) => item.department_id);
    setSelectDepartmentId(checked ? allDepartmentIds : []);
  };

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id, record) => {
        return (
          <Switch
            size="small"
            checked={
              selectAllChecked || selectedLocationId.includes(location_id)
            }
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
  };

  const handleSelectAllLocation = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);
    const allLocationIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allLocationIds : []);
  };

  const onFinish = async () => {
    toggleLoader(true);
    //? weekend is sunday
    //? isSaturday is saturday
    const {
      eligible,
      leave_category_name,
      number_of_leaves,
      weekend,
      holiday,
      isSaturday,
    } = data;
    const { code } = await CALL_API(`owner-leave/${ClientId}`, "post", {
      allocation: "YEARLY",
      eligible: eligible,
      leavecategoryname: leave_category_name,
      numberofleaves: number_of_leaves,
      department: selectDepartmentId,
      location: selectedLocationId,
      weekend: weekend,
      holiday: holiday,
      isSaturday: isSaturday,
    });
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      fetchMasterData();
      setData({});
      setSelectDepartmentId([]);
      setSelectedLocationId([]);
      closeModal(true);
      setStep(0);
      message.success("Successfully Added");
      getLeaveList();
    }
  };

  return (
    <>
      <>
        <label
          className="bg-transparent border-0 mb-3 text-left styled-cursor"
          onClick={() => closeModal(false)}
        >
          Leave <ArrowForwardIosIcon className="px-1" />
        </label>{" "}
        <b className="pb-0 pt-1">Add Leave</b>
        <StepsProgress stepdata={stepdata} title="Add Leave:" step={step} />
        {AddLeaveForm && (
          <div className="styled-card">
            {step == 0 && (
              <>
                <GridBuilder
                  formStructureJson={AddLeaveForm}
                  handleChange={handleFormChange}
                  formSubmit={handleSubmitForm}
                  initialValues={data}
                />
              </>
            )}

            {step == 1 && (
              <>
                {departmentList?.length > 0 && (
                  <Checkbox
                    className="mt-3"
                    checked={selectAllDepartmentChecked}
                    onChange={handleSelectAllDepartment}
                  >
                    Select All
                  </Checkbox>
                )}

                <SelectTable
                  step={step}
                  setStep={setStep}
                  columns={departmentcolumns}
                  tabledata={departmentList}
                  setOpenModal={() => setOpenDepartmentModal(true)}
                  Name="Add Department"
                />
                <FormModal
                  isModalVisible={openDepartmentModal}
                  closeModal={() => setOpenDepartmentModal(false)}
                  Name="Department"
                />
              </>
            )}

            {step == 2 && (
              <>
                {locationBasedOnCountry?.length > 0 && (
                  <Checkbox
                    className="mt-3"
                    checked={selectAllChecked}
                    onChange={handleSelectAllLocation}
                  >
                    Select All
                  </Checkbox>
                )}

                <SelectTable
                  step={step}
                  setStep={setStep}
                  columns={locatincolumns}
                  tabledata={locationBasedOnCountry}
                  setOpenModal={() => setOpenLocationModal(true)}
                  Name="Add Location"
                />
                <FormModal
                  isModalVisible={openLocationModal}
                  closeModal={() => setOpenLocationModal(false)}
                  Name="Location"
                />
              </>
            )}
            {step == 3 && (
              <InReview
                step={step}
                setStep={setStep}
                BasicInformation={data}
                department={selectDepartmentId}
                location={selectedLocationId}
                create={onFinish}
                loading={showLoader}
              />
            )}
          </div>
        )}
      </>
    </>
  );
};
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  locationList: getLocations(state),
  departmentList: getAllDepartmentList(state),
  leaveList: getLeaveCategoryList(state)
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBreakTypes: setBreakTypesList,
      saveLeaveCategoryList: setLeaveCategoryList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(AddLeaves);
