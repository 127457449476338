import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Modal, message, Button } from "antd";
import moment from 'moment-timezone';

import velogic from "../assets/gcs-velogic.png";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setUserRole,
  setUsernName,
  toggleProcessingModal,
  setRoleList
} from "redux/actions";
// Theme
import styled from "@emotion/styled";
// Common
import FONTS from "common/Fonts";
import COLORS from "common/Colors";
import { LOGIN_BASE_URL, NON_EMPTY_REGEX, STATUS_CODE } from "common/Constants";
import ResetPasswordModal from "organisms/ResetPasswordModal";
import SwitchUserModal from "organisms/SwitchUserModal";
import { CALL_API } from "common/API";
import { DEVICE } from "common/Device";
import { getUserRoleId } from "redux/selectors";
import axios from "axios";
import logo from '../assets/logo.png'
import UIModal from "reusableComponent/UIModal";
import ForgotPassword from "organisms/ForgotPassword";

const DivContainer = styled.div`
  height: 100%;
  padding-top: 15%;
  padding: 10px 10px 10px 10px;
  background-image: url('login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-height: 625px) {
    justify-content: flex-start;
  }
`
const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  .logo {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    padding: 40px 30px 30px 40px;
    border-radius: 26px;
  }

  /* height very lower then position should be relative */
  @media (max-height: 625px) {
    position: relative;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  border-radius: 26px;
  box-shadow: 0px 3px 6px #00000029;
`

const StyledCenterAlign = styled.h3`
  text-align: left;
  font-size: 1.4rem;
  padding-bottom: 10px;
  padding-top: 30px;
  font-weight: ${FONTS.WEIGHT.HEAVY};
`

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: ${COLORS.TEXT.WHITE};
  }

  .ant-form-item {
    margin-bottom: 1.5rem !important;
    .ant-form-item-control-input-content {
      input {
        border-radius: 12px;
        border: 1px solid #d9d9d9;
        height: 60px;
        padding-left: 20px;
        color: ${COLORS.TEXT.PRIMARY};
        font-size: 1rem;
        font-weight: ${FONTS.WEIGHT.MEDIUM};
        &:focus {
          border-color: ${COLORS.PRIMARY};
        }
      }
      .ant-input-affix-wrapper.ant-input-password {
        border-radius: 12px;
        padding: 0px;
        input {
          border: none;
        }
      }

      .ant-input-suffix {
        padding: 0px 8px 0px 8px;
        margin: 0px;
      }
    }
  }
`

const DottedLine = styled.div`
  border-bottom: 2px dashed #000;
  margin: 2rem 0rem 1rem 0rem;
`

const StyleSubmitButton = styled(Button)`
  font-weight: bold;
  height: 60px;
  width: 100%;
  border-radius: 12px;
  color: #000000;
`

const StyledErrorDiv = styled.div`
  color: #ff4d4d;
  font-weight: bold;
  /* text-shadow: 0.5px 0.5px black; */
  font-size: 0.8rem;
  padding-top: 10px;
`

const ResetPasswordLink = styled.div`
  cursor: pointer;
`

const Login = ({
  setLoggedIn,
  userRole,
  saveUsernName,
  updateProcessingModal,
  saveRoleList,
}) => {
  const [userRoleModal, setUserRoleModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
      const getLocation = () => {
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                  (position) => {
                      setLatitude(position.coords.latitude);
                      setLongitude(position.coords.longitude);
                  },
                  (error) => {
                      console.error('Error obtaining location:', error);
                      alert('Unable to retrieve your location. Please ensure location services are enabled.');
                  }
              );
          } else {
              alert('Geolocation is not supported by this browser.');
          }
      };

      getLocation(); // Call the function when the component mounts
  }, []);

  const onFinish = async (values) => {
    // setErrorMessage("");
    // updateProcessingModal(true);
    // const { code, first_name, last_name, role_id, token, roleList } =
    //   await CALL_API("authenticate-user", "post", {
    //     username: values.username,
    //     password: values.password,
    //   });
    // if (code === STATUS_CODE.AUTHENTICATION_FAILED) {
    //   setErrorMessage(
    //     "Authentication failed. Please enter correct credentials."
    //   );
    // } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
    //   setErrorMessage("Something is wrong with data.");
    // } else if (code === STATUS_CODE.AUTHENTICATION_SUCCESSFUL) {
    //   if (navigator.userAgent === "application") {
    //     if (
    //       window.localStorage.getItem("username") !== values.username &&
    //       window.localStorage.getItem("password") !== values.password
    //     ) {
    //       const { confirm } = Modal;
    //       confirm({
    //         icon: "",
    //         closable: true,
    //         content: "Do you want to save credentials?",
    //         async onOk() {
    //           window.localStorage.setItem("username", values.username);
    //           window.localStorage.setItem("password", values.password);
    //           setUserRoleModal(true);
    //         },
    //         onCancel() {
    //           setUserRoleModal(true);
    //         },
    //       });
    //     } else {
    //       if (role_id !== 4) setUserRoleModal(true);
    //       if (role_id !== 5) setUserRoleModal(true);
    //     }
    //   }
    //   window.localStorage.setItem("token", token);
    //   saveRoleList(roleList);
    //   userRole(role_id);
    //   saveUsernName(`${first_name} ${last_name}`);

    //   if (role_id === 4) {
    //     setLoggedIn();
    //   } else {
    //     if (navigator.userAgent !== "application") setUserRoleModal(true);
    //   }
    //   if (role_id === 5) {
    //     setLoggedIn();
    //   } else {
    //     if (navigator.userAgent !== "application") setUserRoleModal(true);
    //   }
    // }
    // updateProcessingModal(false);
    try {
      setErrorMessage("");
      updateProcessingModal(true);
      const checkInTime = moment().tz(moment.tz.guess()).format(); // ISO format
      const checkInDate = moment().tz(moment.tz.guess()).format('YYYY-MM-DD');
      const timeZone = moment.tz.guess(); 
      const payload = {
        email: values?.username?.toLowerCase().trim(),
        password: values?.password.trim(),
        CheckInTime: checkInTime,
        CheckInDate: checkInDate,
        GeoMap: [{ latitude, longitude }],
        TimeZone: timeZone,
      };
      const response = await axios.post(`${LOGIN_BASE_URL}user/login`, payload);
      const token = response?.data?.authorizationToken;
      if (response?.data?.status) {
        window.localStorage.setItem("token", token);
        sessionStorage.setItem('tokenTimestamp', new Date().getTime().toString());
        setLoggedIn();
        updateProcessingModal(false);
      } else {
        message.error(response?.data?.message);
      }
      updateProcessingModal(false);

    } catch (error) {
      message.error("Something went wrong");
      console.error("Error fetching data:", error);
      updateProcessingModal(false);
    }


  };

  const onFinishFailed = (errorInfo) => {
    console.log("[onFinishFailed] Failed -->", errorInfo);
  };

  return (
    <DivContainer>
      <LogoContainer>
        <img className="logo" src={logo} alt="logo" />
      </LogoContainer>

      <StyledContainer>
        {!openResetPasswordModal && (
          <div>

            <StyledCenterAlign>Login to your account</StyledCenterAlign>
            <StyledForm
              layout="vertical"
              name="basic"
              initialValues={{
                username: navigator.userAgent === 'application' ? window.localStorage.getItem('username') || '' : '',
                password: navigator.userAgent === 'application' ? window.localStorage.getItem('password') || '' : '',
                remember: true
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Username is required'
                  }
                ]}
              >
                <Input placeholder="User Name" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: 'Password is required'
                  }
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <StyleSubmitButton type="primary" htmlType="submit">
                Log In
              </StyleSubmitButton>

              <DottedLine />

              <Row>
                <Col span={12} className="font-semi-bold font-italic">
                  <ResetPasswordLink onClick={() => setOpenResetPasswordModal(true)}>Forgot Password?</ResetPasswordLink>
                </Col>
              </Row>

              {errorMessage && <StyledErrorDiv className="align-center">{errorMessage}</StyledErrorDiv>}
            </StyledForm>
          </div>
        )}
        {openResetPasswordModal && (
          <div>
            <ForgotPassword onChange={() => {
              setOpenResetPasswordModal(!openResetPasswordModal)
            }} />
            <DottedLine />
            <Row>
              <Col span={12} className="font-semi-bold font-italic">
                <ResetPasswordLink onClick={() => setOpenResetPasswordModal(!openResetPasswordModal)}>Login</ResetPasswordLink>
              </Col>
            </Row>

          </div>
        )}

      </StyledContainer>

      <SwitchUserModal
        onDashboardPg={false}
        userRoleModal={userRoleModal}
        closeModal={() => {
          setUserRoleModal(false);
          setLoggedIn(true);
        }}
      />

      {/* <UIModal
        open={openResetPasswordModal}
        onClose={() => {
          setOpenResetPasswordModal(!openResetPasswordModal)
        }}
        header={<div></div>}
        size='sm'
      >


      </UIModal> */}

      {/* <ResetPasswordModal
        openResetPasswordModal={openResetPasswordModal}
        toggleResetPasswordModal={() =>
          setOpenResetPasswordModal(!openResetPasswordModal)
        }
      ></ResetPasswordModal> */}
    </DivContainer>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRoleList: setRoleList,
      userRole: setUserRole,
      saveUsernName: setUsernName,
      updateProcessingModal: toggleProcessingModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
