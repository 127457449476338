import Title from "atoms/Title";
import TabsList from "molecules/TabsList";
import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  notification,
  Modal,
  Spin,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";
import Button from "atoms/Button";
import styled from "styled-components";
import ClientFields from "./ClientFields";
import InformationFields from "./InformationFields";
import StakeHolderFields from "./StakeHolderFields";
import FinancialResourcePlanFields from "./FinancialResourcePlanFields";
import JobCloserFields from "./JobCloserFields";
import { useMutate } from "hooks/useMutate";
import queryKeys from "common/queryKey";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useUpdate } from "hooks/useUpdate";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserInfo,
  getTempEngagementFormData,
  getUserList,
  getEngagementSelectedTab,
} from "redux/selectors";
import axios from "axios";
import { sumBy } from "lodash";
import { useQueryClient } from "react-query";
import {
  setTempEngagementFormData,
  setselectedengagementtab,
  toggleProcessingModal,
} from "redux/actions";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation } from "react-router-dom";
import { useScrollFetch2 } from "hooks/useScrollFetch2";

const labelStyle = {
  width: "150px",
  display: "inline-block",
  textAlign: "start",
};

const AddNewEngagement = ({ engagementData, update = false }) => {
  const dispatch = useDispatch();
  const [tabId, setTabId] = useState(1);
  const history = useHistory();
  const userInfo = useSelector((state) => getUserInfo(state));
  const userList = useSelector((state) => getUserList(state));

  const [deletedbookedUserId, setDeletedbookedUserId] = React.useState([]);

  const tempEngagementFormData = useSelector((state) =>
    getTempEngagementFormData(state)
  );

  const selectedEngagementTab = useSelector((state) =>
    getEngagementSelectedTab(state)
  );
  const [tempEngagementId] = useState(Date.now());
  const formattedtempEngagementId = "PRJ" + tempEngagementId?.toString();
  const queryClient = useQueryClient();
  const location = useLocation();
  const engagementId = engagementData?.id ?? formattedtempEngagementId;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBackClick = () => {
    dispatch(setselectedengagementtab(tabId));
    history.goBack();
    dispatch(setselectedengagementtab());
  };

  const handleTabSelect = (id) => {
    setTabId(Number(id));
    dispatch(setselectedengagementtab(id));
  };

  useEffect(() => {
    const storedTabId = selectedEngagementTab;
    if (storedTabId) {
      setTabId(Number(storedTabId));
    } else {
      setTabId(1);
    }
  }, [location.pathname]);

  const [form] = Form.useForm();

  const {
    addEngagement: addEngagementQuery,
    updateEngagement: updateEngagementQuery,
    postEngagementDocument,
    getEngagementDocument,
    getEngagementById,
    getEngagements,
    getEngagementDocumentList,
    postEngagementDocumentList,
  } = queryKeys;

  const { key: addEngagementKey, url: addEngagementUrl } =
    addEngagementQuery(false);

  const { mutate: addEngagement, isLoading: isPosting } = useMutate(
    addEngagementKey,
    addEngagementUrl
  );

  useEffect(() => {
    dispatch(toggleProcessingModal(isPosting));
  }, [isPosting]);

  const { mutate: updateEngagement, isLoading: isUpdating } = useUpdate(
    updateEngagementQuery.key,
    updateEngagementQuery.url
  );

  // const { key: getEngagementDocumentKey, url: getEngagementDocumentUrl } =
  //   getEngagementDocument(engagementId);
  const queryParams = new URLSearchParams();
  queryParams.append(
    "engagementid",
    engagementData?.engagementId || tempEngagementFormData?.engagement_id
  );

  const queryString = queryParams.toString();

  const { key: getEngagementDocumentKey, url: getEngagementDocumentUrl } =
    getEngagementDocumentList(engagementData?.engagementId, `?${queryString}`);

  const {
    data,
    lastElementRef,
    isLoading: isDocumentLoading,
    refetch: refetchDocuments,
  } = useScrollFetch2(
    getEngagementDocumentKey,
    {
      endPoint: getEngagementDocumentUrl,
      queryParam: "lastEvaluatedKey",
    },
    {
      enabled: !!engagementData?.engagementId,
    }
  );
  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.response?.items === undefined) return acc;
        return [...acc, ...page?.response?.items];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    if (dataList) {
      const loeDocument = dataList?.filter((val) => val?.category === "LOE");
      const coiDocument = dataList?.filter((val) => val?.category === "COI");

      if (loeDocument.length > 0) {
        const loeDocumentFileList = loeDocument.map((doc) => ({
          url: doc.fileurl,
          name: doc.filename,
        }));

        form.setFieldValue("loe_document_upload", loeDocumentFileList);
      }

      if (coiDocument) {
        const coiDocumentFileList = coiDocument.map((doc) => ({
          url: doc.fileurl,
          name: doc.filename,
        }));
        form.setFieldValue("coi_document_upload", coiDocumentFileList);
      }
    }
  }, [dataList, form]);

  const { mutate: uploadLOEDocument } = useMutate(
    postEngagementDocument.key,
    postEngagementDocument.url + "?docType=LOE"
  );

  const { mutate: uploadCOIDocument } = useMutate(
    postEngagementDocument.key,
    postEngagementDocument.url + "?docType=COI"
  );

  const uploadImageToS3 = async (base64Data, url) => {
    if (!base64Data || !url) {
      notification.error({
        message: "Error uploading image to S3",
      });
    }
    try {
      const res = await axios.put(url, base64Data);

      setTimeout(() => {
        refetchDocuments();
      }, 1000);

      return res;
    } catch (error) {
      console.error("Error uploading image to S3", error);
    }
  };

  const { mutate: documentadd, isLoading: isdocumentPosting } = useMutate(
    postEngagementDocumentList.key,
    postEngagementDocumentList.url
  );

  const onUploadtoDocument = (file, base64, type) => {
    const payload = {
      clientid: engagementData?.clientId || tempEngagementFormData?.client,
      engagementid:
        engagementData?.engagementId || tempEngagementFormData?.engagement_id,
      category: type,
      filename: file?.name,
      attachmenttype: file?.type,
      description: "-",
      content: base64,
      createdby: userInfo?.userid,
      updatedby: userInfo?.userid,
    };
    documentadd(payload, {
      onSuccess: (res) => {
        // notification.success({
        //   message: "Document Added Successfully",
        // });
      },
    });
  };

  const handleUploadDocument = async (file, base64, type = false) => {
    // if (gotEngagementId) {
    //   // do nothing
    // } else if (!update) return; //? while adding new engagement, the document upload will be called only after the engagement is created

    const actualFile = file.originFileObj || file.file;
    onUploadtoDocument(actualFile, base64, type);

    // const data = {
    //   engagementId: engagementId || gotEngagementId,
    //   fileName: actualFile?.name,
    // };

    // if (docType === "LOE") {
    //   uploadLOEDocument(data, {
    //     onSuccess: (res) => {
    //       uploadImageToS3(actualFile, res?.url);
    //     },
    //   });
    // }

    // if (docType === "COI") {
    //   uploadCOIDocument(data, {
    //     onSuccess: (res) => {
    //       uploadImageToS3(actualFile, res?.url);
    //     },
    //   });
    // }
  };

  const handleValuesChange = (changedValues) => {
    // Get all form values
    const formValues = form.getFieldsValue(true);

    // Check if specific fields have changed
    const isPartnerChanged = changedValues.partner !== undefined;
    const isRelationshipPartnerChanged =
      changedValues.relationship_partner !== undefined;
    const isPersonInchargeChanged = changedValues.person_incharge !== undefined;
    const isAuditor = changedValues.isauditor !== undefined;
    // Initialize the payload with the necessary values

    // Update the resourceList if any of the specified fields changed
    if (
      isPartnerChanged ||
      isPersonInchargeChanged ||
      isRelationshipPartnerChanged ||
      isAuditor
    ) {
      let seletedUserId =
        changedValues?.partner ||
        changedValues.relationship_partner ||
        changedValues.person_incharge ||
        changedValues.isauditor;
      let selectedResource = userList.find(
        (val) => val.userid === seletedUserId
      );

      const payload = {
        userId: selectedResource?.userid || null,
        employeeName: selectedResource?.fullname || null,
        employeeId: selectedResource?.employeeid || null,
        accessRole: form?.access_role || null,
        projectRole: isPartnerChanged
          ? "Partner"
          : isPersonInchargeChanged
          ? "Person Incharge"
          : isRelationshipPartnerChanged
          ? "QA Partner"
          : "Is Auditor",
        actualBookedStartDate: null,
        actualBookedEndDate: null,
        engagementId: tempEngagementFormData?.engagement_id,
        engagementName: tempEngagementFormData?.job_title || null,
        clientId: tempEngagementFormData?.client || null,
        effort: 0,
        remarks: null,
        personDay: null,
        resourceExtRate: selectedResource?.chargeoutrate || null,
        status: "Assigned",
        engagementStartDate: null,
        engagementEndDate: null,
        weekList: null,
        justification: null,
        managingOffice: tempEngagementFormData?.managing_office,
      };

      const currentResourceList = tempEngagementFormData?.resourceList || [];

      // Check if the resourceList already contains an object with the same projectRole
      const updatedResourceList = currentResourceList.map((item) =>
        item.projectRole === payload.projectRole ? payload : item
      );

      // If the payload's projectRole does not match any existing item, add it
      if (
        !currentResourceList.some(
          (item) => item.projectRole === payload.projectRole
        )
      ) {
        updatedResourceList.push(payload);
      }

      // Update the formValues with the new resourceList
      const updatedFormValues = {
        ...formValues,
        resourceList: updatedResourceList,
        subcontractList: tempEngagementFormData?.subcontractList || [],
      };

      // Update subcontractList in formValues with subcontractList from tempEngagementFormData
      updatedFormValues.subcontractList =
        tempEngagementFormData?.subcontractList || [];
      // Dispatch updated form values to Redux
      dispatch(setTempEngagementFormData(updatedFormValues));
    } else {
      // Update subcontractList in formValues with subcontractList from tempEngagementFormData
      formValues.subcontractList =
        tempEngagementFormData?.subcontractList || [];
      const updatedFormValues = {
        ...formValues,
        resourceList: tempEngagementFormData?.resourceList || [],
        subcontractList: tempEngagementFormData?.subcontractList || [],
      };
      // Dispatch the original form values if no specific fields changed
      dispatch(setTempEngagementFormData(updatedFormValues));
    }
  };

  useEffect(() => {
    if (engagementData) {
      // console.log("useEffect  engagementData--->", engagementData)
      form.setFieldsValue(initialValues(engagementData, {}, null, form));
    } else {
      const filteruser = userList.find(
        (val) => val?.userid === userInfo?.userid
      );
      form.setFieldsValue(
        initialValues(
          {},
          tempEngagementFormData,
          formattedtempEngagementId,
          form,
          filteruser
        )
      );
    }
  }, [engagementData, form]);

  const isFormDisable = () => {
    const engagementStatus = engagementData?.jobCloser?.status;

    const userDetails = userList.find(
      (val) => val?.userid === userInfo?.userid
    );

    const userManaginOffice = userDetails?.managingoffice?.[0];
    

    if (engagementStatus === "Active") {
      return true;
    }

    const isPartnerOrManagerOrHeadOfficeUser =
      userInfo?.userid === engagementData?.stakeHolder?.personIncharge ||
      userInfo?.userid === engagementData?.stakeHolder?.partner ||
      userManaginOffice === "Head Office";

    return update ? !isPartnerOrManagerOrHeadOfficeUser : false;
  };

  const isAddResorceAndSubDisable = () => {
    // const engagementStatus = engagementData?.jobCloser?.status;

    // if (engagementStatus === "Active") {
    //   return true;
    // }

    const isPartnerOrManager =
      userInfo?.userid === engagementData?.stakeHolder?.personIncharge ||
      userInfo?.userid === engagementData?.stakeHolder?.partner;
    return update ? !isPartnerOrManager : false;
  };

  const addResourceandSubDisabled = isAddResorceAndSubDisable();

  const formdisabled = isFormDisable();

  const handleSubmit = (values) => {
    return new Promise((resolve, reject) => {
      if (update) {
        const updatedData = {
          ...engagementData,
          ...formsToAttributes(form.getFieldsValue(true), {}, form),
          deletedResourceIds: deletedbookedUserId, // Update the resourceList with the bookedUserList state
        };

        updateEngagement(updatedData, {
          onSuccess: (res) => {
            if (res.status) {
              notification.success({
                message: res?.message,
              });
            } else {
              form?.setFieldValue('status', engagementData?.jobCloser?.status);
              notification.error({
                message: res?.message,
              });
            }

            queryClient.invalidateQueries(getEngagementById(engagementId).key);
            queryClient.invalidateQueries(getEngagements.key);
            resolve(); // resolve the promise here
          },
          onError: (error) => {
            reject(error); // reject if there's an error
          },
        });
      } else {
        const data = {
          ...formsToAttributes(
            tempEngagementFormData,
            tempEngagementFormData,
            form
          ),
          createdBy: userInfo?.userid,
        };
        if (
          tempEngagementFormData?.job_title &&
          tempEngagementFormData?.partner &&
          tempEngagementFormData?.billing_office
        ) {
          addEngagement(data, {
            onSuccess: (res) => {
              notification.success({
                message: "Engagement Added Successfully",
              });
              dispatch(setTempEngagementFormData(""));
              history.push("/engagement");
              resolve(); // resolve the promise
            },
            onError: (error) => {
              reject(error); // reject if there's an error
            },
          });
        } else {
          notification.error({
            message: "Please fill all the required fields",
          });
        }
      }
    });
  };

  const submitEngagement = async () => {
    try {
      // Validate the form before showing the modal
      const values = await form.validateFields();
      if (!update && values?.pfa_status === "Not Approved") {
        Modal.confirm({
          icon: "",
          content: (
            <div style={{ textAlign: "center" }}>
              <p>
                This engagement requires approval from the partner. It will not
                appear in the timesheet until the partner has given their
                approval.
              </p>
              <p>Would you like to proceed?</p>
            </div>
          ),
          okText: (
            <span>{isSubmitting ? <Spin size="small" /> : null} YES</span>
          ),
          cancelText: "No",
          onOk: async () => {
            setIsSubmitting(true);
            try {
              await handleSubmit(values);
            } finally {
              setIsSubmitting(false);
            }
          },
          onCancel: () => {
            setIsSubmitting(false);
          },
        });
      } else {
        try {
          await handleSubmit(values);
        } finally {
        }
      }
    } catch (error) {
      console.error("[submitEngagement] error -->", error);
      setIsSubmitting(false);
    }
  };

  const activeUserslist = userList.filter((item) => item.statusid === "1");

  // const userListOptions = userList
  //   .filter(
  //     (user) =>
  //       user?.designation === "Managing Partner" ||
  //       user?.designation === "Partner" ||
  //       user?.designation === "Senior Partner"
  //   )
  //   .map((user) => ({
  //     label: user.fullname,
  //     value: user.userid,
  //   }));

  const allUserListOptions = activeUserslist.map((user) => {
    return {
      label: user.fullname,
      value: user.userid,
    };
  });

  const [showCloseEngagementModal, setShowCloseEngagementModal] =
    useState(false);

  const handleCloseEngagementViaModal = async () => {
    setShowCloseEngagementModal(true);
  };

  const handleOkCloseEngagement = async () => {
    setIsSubmitting(true);
    try {
      const values = await form.validateFields();
      await handleSubmit(values);
      setShowCloseEngagementModal(false);
      setIsSubmitting(false);
    } catch (error) {
      console.error("[handleCloseEngagement] error -->", error);
      setIsSubmitting(false);
    }
  };

  const handleCancelCloseEngagementModal = () => {
    setIsSubmitting(false);
    setShowCloseEngagementModal(false);
    form.setFieldsValue({ status: "Active" });
  };
  const items = [
    {
      id: 1,
      label: "Client",
      component: (
        <ClientFields
          isDocumentLoading={isDocumentLoading}
          handleUploadDocument={handleUploadDocument}
          engagementID={engagementData?.engagementId}
          engagementData={engagementData}
          update={update}
          disabled={formdisabled}
        />
      ),
    },
    {
      id: 2,
      label: "Information",
      component: <InformationFields disabled={formdisabled} />,
    },
    {
      id: 3,
      label: "Stake Holder",
      component: (
        <StakeHolderFields
          update={update}
          engagementData={engagementData}
          disabled={formdisabled}
        />
      ),
    },
    {
      id: 4,
      label: "Financial & Resource Plan",
      component: (
        <FinancialResourcePlanFields
          update={update}
          setDeletedbookedUserId={setDeletedbookedUserId}
          disabled={formdisabled}
          addResourceandSubDisabled={addResourceandSubDisabled}
        />
      ),
    },
    {
      id: 5,
      label: "Job Closure",
      component: (
        <JobCloserFields
          update={update}
          handleCloseEngagementViaModal={handleCloseEngagementViaModal}
          disabled={formdisabled}
        />
      ),
    },
  ];

  return (
    <AddEngagementWrap>
      {!update && (
        <div style={{ display: "flex" }}>
          <div>
            <label
              className="bg-transparent border-0 mb-3 text-left styled-cursor"
              onClick={handleBackClick}
            >
              <span className="">Back</span>{" "}
              <ArrowForwardIosIcon className="px-1" />
            </label>{" "}
          </div>
          <div>{!update && <Title>Add New Engagement</Title>}</div>
        </div>
      )}
      <Form
        initialValues={initialValues(
          engagementData,
          tempEngagementFormData,
          formattedtempEngagementId,
          form
        )}
        onValuesChange={handleValuesChange}
        layout="horizontal"
        form={form}
        // onFinish={handleSubmit}
        colon={false}
        requiredMark={false}
        onFinishFailed={() => {
          notification.error({
            message: "Please fill all the required fields",
          });
        }}
      >
        <TabsList
          items={items}
          handleTabSelect={handleTabSelect}
          tabId={tabId}
          forceRender={true}
        />
        {!addResourceandSubDisabled && (
          <div className="form-footer">
            <Button
              type="primary"
              htmlType="submit"
              loading={isPosting || isUpdating}
              onClick={() => submitEngagement()}
              disabled={
                update
                  ? form.getFieldValue("pfa_status") === "Not Approved"
                  : false
              }
            >
              Save
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
                dispatch(setTempEngagementFormData(""));
                history.push("/engagement");
              }}
            >
              Close
            </Button>
          </div>
        )}
      </Form>

      <Modal
        title="Close Engagement"
        open={showCloseEngagementModal}
        onOk={handleOkCloseEngagement}
        onCancel={handleCancelCloseEngagementModal}
        okText={isSubmitting ? <Spin size="small" /> : "YES"}
        cancelText="No"
      >
        <div>
          <p
            style={{
              fontWeight: "500",
              marginBottom: "16px",
            }}
          >
            Are you sure you want to close this engagement?
          </p>

          <Form form={form} colon={false}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={<span style={labelStyle}>Closed Date</span>}
                  name="closeddate"
                  rules={[
                    { required: true, message: "Please enter Closed Date!" },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span style={labelStyle}>Partner</span>}
                  name="closedbypartner"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Partner!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    showSearch={true}
                    options={allUserListOptions}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={<span style={labelStyle}>Person Incharge</span>}
                  name="closedbyperson_incharge"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Person Incharge!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    options={allUserListOptions}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </AddEngagementWrap>
  );
};

export default AddNewEngagement;

export const Required = () => {
  return <span style={{ color: "red" }}>*</span>;
};

const AddEngagementWrap = styled.div`
  .fields-card {
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
  }
  .ant-tabs-tab {
    padding: 5px 0;
  }
  .ant-tabs-nav {
    color: #000000;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-nav {
    background-color: #f3fefa;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .profit {
    color: #4caf50;
    font-weight: 600;
  }
  .loss {
    color: #f44336;
    font-weight: 600;
  }
  .ant-picker {
    width: 100%;
  }
  .form-footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .max-char-info {
    font-size: 12px;
    color: #bfbfbf;
    margin-top: -8px;
    text-align: end;
  }
  .ant-checkbox.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #02b209 !important;
      border-color: #02b209 !important;
    }
  }
  .ant-upload.ant-upload-select.ant-upload-select-text {
    width: 100%;
  }
  .job-closer-buttons-container {
    margin-left: 70px;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  .job-closer-buttons-container .ant-btn-primary {
    border-radius: 5px;
    font-weight: 500;
  }

  .client-name-id {
    display: flex;
    justify-content: start;
    gap: 50px;
    flex-wrap: wrap;

    .client-name {
      font-weight: 600;
    }
  }

  .form-footer .ant-btn-primary {
    border-radius: 5px;
    font-weight: 500;
  }
`;

export const initialValues = (
  engagementData,
  tempEngagementFormData,
  formattedtempEngagementId,
  form,
  filteruser
) => {
  let subcontractoSum =
    sumBy(
      engagementData?.subcontractor ??
        parseInt(tempEngagementFormData?.subcontractor_usd),
      "amount"
    ) ?? "0.00";
  const totalusdamount =
    parseInt(tempEngagementFormData?.billing_amount ?? 0) /
      parseInt(tempEngagementFormData?.conversion_rate ?? 0) || "0.00";
  const loeDocument = form.getFieldValue("loe_document_upload");
  const coiDocumnet = form.getFieldValue("coi_document_upload");
  const clientName = form.getFieldValue("client_name");
  // console.log("clientName --->", clientName)

  return {
    engagement_id: engagementData?.engagementId
      ? engagementData?.engagementId
      : tempEngagementFormData?.engagement_id ?? formattedtempEngagementId,
    proposal_number:
      engagementData?.client?.proposalNumber ??
      tempEngagementFormData?.proposal_number ??
      "",
    managing_office:
      engagementData?.client?.managingOffice ??
      tempEngagementFormData?.managing_office ??
      filteruser?.managingoffice?.toString() ??
      null,
    department:
      engagementData?.client?.department ??
      tempEngagementFormData?.department ??
      null,
    client_competency:
      engagementData?.client?.competency ??
      tempEngagementFormData?.client_competency ??
      null,
    client:
      engagementData?.client?.clientId ??
      tempEngagementFormData?.client ??
      null,
    service_line:
      engagementData?.client?.serviceLine ??
      tempEngagementFormData?.service_line ??
      null,
    client_fy_month:
      engagementData?.client?.clientFyMonth ??
      tempEngagementFormData?.client_fy_month ??
      null,
    loe_ref_no:
      engagementData?.client?.loeRefNo ??
      tempEngagementFormData?.loe_ref_no ??
      null,
    client_name:
      engagementData?.client?.clientName ??
      tempEngagementFormData?.client_name ??
      "",
    loe_document_upload: loeDocument?.length
      ? loeDocument
      : tempEngagementFormData?.loe_document_upload ?? [],
    coi_document_upload: coiDocumnet?.length
      ? coiDocumnet
      : tempEngagementFormData?.coi_document_upload ?? [],
    job_title:
      engagementData?.information?.jobTitle ??
      tempEngagementFormData?.job_title ??
      "",
    approved_date: engagementData?.information?.approvedDate
      ? moment(engagementData?.information?.approvedDate, "DD-MM-YYYY")
      : tempEngagementFormData?.approved_date
      ? moment(tempEngagementFormData?.approved_date, "DD-MM-YYYY")
      : moment(),
    classification:
      engagementData?.information?.classification ??
      tempEngagementFormData?.classification ??
      null,
    stage:
      engagementData?.information?.stage ??
      tempEngagementFormData?.stage ??
      null,
    country:
      engagementData?.information?.country ??
      tempEngagementFormData?.country ??
      null,
    objective:
      engagementData?.information?.objective ??
      tempEngagementFormData?.objective ??
      "",
    partner:
      engagementData?.stakeHolder?.partner ??
      tempEngagementFormData?.partner ??
      null,
    relationship_partner:
      engagementData?.stakeHolder?.relationshipPartner ??
      tempEngagementFormData?.relationship_partner ??
      null,
    person_incharge:
      engagementData?.stakeHolder?.personIncharge ??
      tempEngagementFormData?.person_incharge ??
      null,
    referring_bdo_firm:
      engagementData?.stakeHolder?.referringBdoFirm ??
      tempEngagementFormData?.referring_bdo_firm ??
      null,
    other_side_entity_name:
      engagementData?.stakeHolder?.otherSideEntityName ??
      tempEngagementFormData?.other_side_entity_name ??
      null,
    sent_to_referaal:
      engagementData?.stakeHolder?.sentToReferral ??
      tempEngagementFormData?.sent_to_referaal ??
      "",
    plan_start_date: engagementData?.stakeHolder?.planStartDate
      ? moment(engagementData?.stakeHolder?.planStartDate, "DD-MM-YYYY")
      : tempEngagementFormData?.plan_start_date
      ? moment(tempEngagementFormData?.plan_start_date, "DD-MM-YYYY")
      : "",
    plan_end_date: engagementData?.stakeHolder?.planEndDate
      ? moment(engagementData?.stakeHolder?.planEndDate, "DD-MM-YYYY")
      : tempEngagementFormData?.plan_end_date
      ? moment(tempEngagementFormData?.plan_end_date, "DD-MM-YYYY")
      : "",
    client_fy_start: engagementData?.stakeHolder?.clientFyStart
      ? moment(engagementData?.stakeHolder?.clientFyStart, "DD-MM-YYYY")
      : tempEngagementFormData?.client_fy_start
      ? moment(tempEngagementFormData?.client_fy_start, "DD-MM-YYYY")
      : "",
    client_fy_end: engagementData?.stakeHolder?.clientFyEnd
      ? moment(engagementData?.stakeHolder?.clientFyEnd, "DD-MM-YYYY")
      : tempEngagementFormData?.client_fy_end
      ? moment(tempEngagementFormData?.client_fy_end, "DD-MM-YYYY")
      : "",
    isauditor:
      engagementData?.stakeHolder?.isauditor ??
      tempEngagementFormData?.isauditor ??
      null,
    billing_office:
      engagementData?.financial_resourcePlan?.billingOffice ??
      tempEngagementFormData?.billing_office ??
      null,
    financial_year:
      engagementData?.financial_resourcePlan?.financialYear ??
      tempEngagementFormData?.financial_year ??
      new Date().getFullYear(),
    mandays:
      engagementData?.financial_resourcePlan?.mandays ??
      tempEngagementFormData?.mandays ??
      null,
    currency:
      engagementData?.financial_resourcePlan?.currency ??
      tempEngagementFormData?.currency ??
      "US Dollar",
    billing_amount:
      engagementData?.financial_resourcePlan?.billingAmount ??
      tempEngagementFormData?.billing_amount ??
      "",
    usd_amount:
      engagementData?.financial_resourcePlan?.usdAmount ?? totalusdamount ?? "",
    planned_fee_adjustment:
      engagementData?.financial_resourcePlan?.plannedFeeAdjustment ??
      tempEngagementFormData?.planned_fee_adjustment ??
      "",
    pfa_usd:
      engagementData?.financial_resourcePlan?.pfaUsd ??
      tempEngagementFormData?.pfa_usd ??
      "",
    pfa_status:
      engagementData?.financial_resourcePlan?.pfaStatus ??
      tempEngagementFormData?.pfa_status ??
      "",
    subcontractor_usd: parseInt(subcontractoSum) ?? "",
    status:
      engagementData?.jobCloser?.status ??
      tempEngagementFormData?.status ??
      "Not-Started",
    conversion_rate:
      engagementData?.financial_resourcePlan?.conversion_rate ??
      tempEngagementFormData?.conversion_rate ??
      1,
    resourceList: tempEngagementFormData?.resourceList ?? [],
    subcontractList: tempEngagementFormData?.subcontractList ?? [],
    closeddate: moment(),
    closedbypartner: engagementData?.stakeHolder?.partner ?? null,
    closedbyperson_incharge:
      engagementData?.stakeHolder?.personIncharge ?? null,
  };
};

const formsToAttributes = (values, tempEngagementFormData, form) => {
  return {
    clientId: values.client,
    engagementId: values.engagement_id,
    client: {
      proposalNumber: values.proposal_number,
      managingOffice: values?.managing_office,
      department: values.department,
      competency: values.client_competency,
      clientId: values.client,
      serviceLine: values.service_line,
      clientFyMonth: values.client_fy_month,
      loeRefNo: values.loe_ref_no,
      clientName: values.client_name,
    },
    information: {
      jobTitle: values.job_title,
      classification: values.classification,
      approvedDate: values.approved_date
        ? moment(values.approved_date).format("DD-MM-YYYY")
        : "",
      stage: values.stage,
      country: values.country,
      objective: values.objective,
    },
    stakeHolder: {
      partner: values.partner,
      relationshipPartner: values.relationship_partner,
      personIncharge: values.person_incharge,
      referringBdoFirm: values.referring_bdo_firm,
      otherSideEntityName: values.other_side_entity_name,
      sentToReferral: values.sent_to_referaal,
      isauditor: values.isauditor,
      planStartDate: values.plan_start_date
        ? moment(values.plan_start_date).format("DD-MM-YYYY")
        : "",
      planEndDate: values.plan_end_date
        ? moment(values.plan_end_date).format("DD-MM-YYYY")
        : "",
      clientFyStart: values.client_fy_start
        ? moment(values.client_fy_start).format("DD-MM-YYYY")
        : "",
      clientFyEnd: values.client_fy_end
        ? moment(values.client_fy_end).format("DD-MM-YYYY")
        : "",
    },
    financial_resourcePlan: {
      billingOffice: values.billing_office,
      financialYear: values.financial_year,
      mandays: values.mandays,
      currency: values.currency,
      billingAmount: values.billing_amount,
      usdAmount:
        parseFloat(values.billing_amount) / parseFloat(values?.conversion_rate),
      plannedFeeAdjustment: values.planned_fee_adjustment,
      pfaUsd: form.getFieldValue("pfa_usd"),
      pfaStatus: values.pfa_status,
      subcontractorUsd: parseInt(values.subcontractor_usd),
      conversion_rate: values?.conversion_rate,
    },
    jobCloser: {
      status: values.status,
    },
    closeDate: moment(values.closeddate).format("DD-MM-YYYY"),
    closedByPartner: values.closedbypartner,
    closedByPersonIncharge: values.closedbyperson_incharge,
    loeDocument: values.loe_document_upload || [],
    coiDocument: values.coi_document_upload || [],
    resourceList: tempEngagementFormData?.resourceList ?? [],
    subcontractList: tempEngagementFormData?.subcontractList ?? [],
  };
};
