import React, { useEffect, useState } from 'react'

function UserManual() {

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const styleDiv = { height: windowHeight, overflowY: 'hidden'}
  return (
    <div style={styleDiv}>
      <embed
        src="https://insyts-bdo-usermanual-uat.s3.ap-south-1.amazonaws.com/usermanuals/InsytsUserManual.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default UserManual