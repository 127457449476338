import React, { useContext, useState } from "react";
import moment from "moment";
import { Row, Col, Badge } from "antd";
import { Spin, Modal, message } from "antd";
import {
  dateInDetail,
  getFirstDate,
  getLastDate,
  getMySqlDate,
  isDateBetweenTwoDates,
  momentDate,
} from "utils/Date";
import COLORS from "common/Colors";
import UpcomingHolidays from "organisms/UpcomingHolidays";
import LeaveForm from "organisms/LeaveForm";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getUserRoleId,
  getSelectedDepartmentId,
  getUserInfo,
  getClientid,
  getMenuList,
  getLeaveCategoryList,
  getUserList,
  getRoleList,
} from "redux/selectors";
import {
  concat,
  debounce,
  filter,
  find,
  first,
  get,
  includes,
  map,
  some,
} from "lodash";
import { getAllSubordinates, STATUS_CODE } from "common/Constants";
import LeaveList from "organisms/LeaveList";
import TabsList from "molecules/TabsList";
import {
  SmallCalendar,
  StyledAntCalendarFull,
  StyledAntCalendarSmall,
  StyledDateCell,
  StyleDepartmentDropdown,
  StyledHolidayBadge,
  StyledLeaveCount,
  StyledLeaveText,
  StyledLeftPanel,
  StyledNextIcon,
  StyledPreviousIcon,
  StyledTitle,
  StyledUpcomingHolidaysContainer,
} from "./StyledComponents";
import { CalendarContext } from ".";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import StaffLeaveIndicatorInCalendar from "./StaffLeaveIndicatorInCalendar";
import ReusableCalendar from "./ReusableCalendar";
import LeaveWeekCalendar from "pages/WeekCalendarView/LeaveWeekCalendar";

function LeaveCalendar({
  userRoleId,
  selectedDepartmentId,
  allDepartmentList,
  userInfo,
  ClientId,
  leaveCategoryList,
  menuList,
  userList,
  roleList,
  setTabId,
  tabId,
}) {
  const {
    tabBarExtraContent,
    miniCalendarCurrentDate,
    holidayList,
    allHolidayList,
    previousMonth,
    nextMonth,
  } = useContext(CalendarContext);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  let tabMenus = Menulist.filter(
    (item) =>
      item.key === "mycalendar" ||
      item.key === "teamcalendar" ||
      item.key === "allcalendar"
  );

  let newArray = [];
  newArray.push(...tabMenus);

  const items =
    newArray &&
    newArray
      ?.filter((data) => data.access === true)
      .map((data, index) => ({
        id: data.subtitle,
        label: data.subtitle,
        component: <></>,
      }));

  const [departmentSelectedByHR, setDepartmentSelectedByHR] = useState("");
  const [leaveListFiterForHR, setLeaveListFiterForHR] = useState({});

  const [displayLeaveModal, toggleLeaveModal] = useState(false);
  const [isLeaveListModalVisible, toggleLeaveListModalVisible] =
    useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [showMyTeamLeaves, toggleMyTeamLeave] = useState(false);

  moment.updateLocale("en", {
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
  });
  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const subordinates = getAllSubordinates(userList, filteruser[0]?.employeeid);

  const handleTabSelect = (key) => {
    const activeTab = items.find((item) => item.id === key);
    if (activeTab) {
      setTabId(activeTab.label);
    }

    if (key === 0) {
      toggleMyTeamLeave(!showMyTeamLeaves);
    }
  };

  let payload = {
    status_id: "4,5",
    officesupervisorid: [""],
    selected_role_id: "0",
    start_date: getFirstDate(miniCalendarCurrentDate),
    end_date: getLastDate(miniCalendarCurrentDate),
    show_my_team_leave: true,
  };

  const { key, url } = queryKeys.getLeaveList(
    ClientId,
    JSON.stringify(payload)
  );

  const {
    data: leaveDataRes,
    isLoading: displayLoader,
    refetch: getLeaveList,
  } = useFetch(
    key,
    url,
    {
      enabled: !!ClientId,
    },
    {
      status_id: "4,5",
      officesupervisorid: [""],
      selected_role_id: "0",
      start_date: getFirstDate(miniCalendarCurrentDate),
      end_date: getLastDate(miniCalendarCurrentDate),
      show_my_team_leave: true,
    }
  );

  const leaveList = leaveDataRes?.response?.leaves?.filter(
    (val) => val?.userid === get(userInfo, "userid", "")
  );

  const myTeamLeaveList = leaveDataRes?.response?.teamLeaves?.filter((val) => {
    return subordinates.includes(val?.officesupervisorid);
  });

  const allLeaveList = leaveDataRes?.response?.leaves.filter((val) => {
    if (filteruser[0].OfficeSupervisorId === "none") {
      return val;
    } else {
      const findUser = userList?.filter(
        (item) => item?.employeeid === val.employeeid
      );
      const isSameCountry = some(findUser[0]?.managingoffice, (value) =>
        includes(filteruser[0]?.managingoffice, value)
      );
      return isSameCountry;
    }
  });

  const addLeave = (event, cellDate) => {
    if (
      userRoleId != 1 &&
      event.target.className === "ant-picker-calendar-date-content"
    ) {
      const isPastDate =
        moment(getMySqlDate(cellDate)) < moment(getMySqlDate("2022-08-01"));
      if (!isPastDate) {
        toggleLeaveModal(true);
        setLeaveDetails({
          start_date: cellDate,
          end_date: cellDate,
        });
      }
    }
  };

  const AllTabID =
    tabId === "All" && items?.find((item) => item.id === "All") ? true : false;

  const MYTabID =
    tabId === "Mine" && items?.find((item) => item.id === "Mine")
      ? true
      : false;

  const MYTEAMTabID =
    tabId === "My Team" && items?.find((item) => item.id === "My Team")
      ? true
      : false;

  const dateFullCellRender = (cellDate) => {
    const { date: day, day: Days } = dateInDetail(cellDate);
    const leave = filter(
      leaveList,
      ({ start_date, new_start_date, new_end_date, end_date, status_id }) =>
        includes(["4", "5"], status_id) &&
        isDateBetweenTwoDates(
          cellDate,
          new_start_date === "null" ||
            new_start_date === null ||
            new_start_date === ""
            ? start_date
            : new_start_date,
          new_end_date === "null" ||
            new_end_date === null ||
            new_end_date === ""
            ? end_date
            : new_end_date
        ),
      {}
    );

    const teamLeave =
      (AllTabID || MYTEAMTabID) &&
      filter(
        myTeamLeaveList,
        ({ start_date, new_start_date, new_end_date, end_date, status_id }) =>
          includes(["4", "5"], status_id) &&
          isDateBetweenTwoDates(
            cellDate,
            new_start_date === "null" ||
              new_start_date === "" ||
              new_start_date === null
              ? start_date
              : new_start_date,
            new_end_date === "null" ||
              new_end_date === "" ||
              new_end_date === null
              ? end_date
              : new_end_date
          ),

        {}
      );

    const leaveDetails = first(MYTabID ? leave : teamLeave);
    const filterleavetype = leaveCategoryList?.filter(
      (item) => item.leave_category_id === leaveDetails?.leave_category_id
    );
    const holidayrequired = filterleavetype[0]?.holiday;
    const weekendrequired = filterleavetype[0]?.weekend;
    const isSaturdayrequired = filterleavetype[0]?.isSaturday;
    const statusId = get(leaveDetails, "status_id", "");
    const havingcancelrequest = get(leaveDetails, "having_cancel_request", "");
    const isHoliday = find(
      MYTabID ? holidayList : allHolidayList,
      ({ start_date, end_date }) =>
        isDateBetweenTwoDates(cellDate, start_date, end_date)
    );
    const leaveType =
      statusId === "4" &&
      (weekendrequired === "Yes" ? Days : Days !== "Sunday") &&
      (isSaturdayrequired === "Yes" ? Days : Days !== "Saturday") &&
      (holidayrequired === "Yes" || MYTEAMTabID ? Days : !isHoliday)
        ? "has-pending-leaves"
        : havingcancelrequest === "1" &&
          (weekendrequired === "Yes" ? Days : Days !== "Sunday") &&
          (isSaturdayrequired === "Yes" ? Days : Days !== "Saturday") &&
          (holidayrequired === "Yes" || MYTEAMTabID ? Days : !isHoliday)
        ? "has-pending-leaves"
        : statusId === "5" &&
          (weekendrequired === "Yes" ? Days : Days !== "Sunday") &&
          (isSaturdayrequired === "Yes" ? Days : Days !== "Saturday") &&
          (holidayrequired === "Yes" || MYTEAMTabID ? Days : !isHoliday)
        ? "has-approved-leaves"
        : "";

    const leaveColor = get(isHoliday, "color", "");
    const hrPendingLeaves = filter(
      allLeaveList,
      ({
        start_date,
        end_date,
        status_id,
        having_cancel_request,
        new_start_date,
        new_end_date,
      }) =>
        (status_id === "4" &&
          isDateBetweenTwoDates(cellDate, start_date, end_date)) ||
        (status_id === "5" &&
          having_cancel_request === "1" &&
          // (weekendrequired === "Yes" ? Days : Days != "Sunday") &&
          // (isSaturdayrequired === "Yes" ? Days : Days !== "Saturday") &&
          // (holidayrequired === "Yes" ? Days : !isHoliday) &&
          isDateBetweenTwoDates(cellDate, new_start_date, new_end_date)),
      {}
    ).length;

    const hrApprovedLeaves = filter(
      allLeaveList,
      ({ start_date, end_date, status_id, having_cancel_request }) =>
        status_id === "5" &&
        (having_cancel_request === "0" || having_cancel_request === "") &&
        // (weekendrequired === "Yes" ? Days : Days != "Sunday") &&
        // (isSaturdayrequired === "Yes" ? Days : Days !== "Saturday") &&
        // (holidayrequired === "Yes" ? Days : !isHoliday) &&
        isDateBetweenTwoDates(cellDate, start_date, end_date),
      {}
    ).length;
    return (
      <>
        {MYTabID && (
          <StyledDateCell
            onClick={(event) =>
              Menulist?.find(
                (item) => item?.key === "mycalendar" && item?.permission?.create
              )
                ? addLeave(event, cellDate)
                : ""
            }
          >
            <span className={`day ${leaveType}`}>
              {leaveColor && (
                <StyledHolidayBadge
                  status="success"
                  size="small"
                  color={leaveColor}
                >
                  H
                </StyledHolidayBadge>
              )}
              {leaveType &&
                MYTabID &&
                map(leave, (leaveDetails) => (
                  <StaffLeaveIndicatorInCalendar
                    icon="team"
                    leaveDetails={leaveDetails}
                    isStaff={true}
                    day={day}
                    openEditLeaveModal={(details) => {
                      cancelLeaveRequest(details);
                    }}
                    EditLeaveModal={(details) => {
                      cancelLeave(details);
                    }}
                  >
                    {day}
                  </StaffLeaveIndicatorInCalendar>
                ))}
              {!leaveType && day}
            </span>
            <div className="ant-picker-calendar-date-content"></div>
          </StyledDateCell>
        )}

        {MYTEAMTabID && (
          <StyledDateCell>
            <b>{day}</b>
            {leaveColor && (
              <StyledHolidayBadge
                status="success"
                size="small"
                color={leaveColor}
              >
                H
              </StyledHolidayBadge>
            )}
            <div className="ant-picker-calendar-date-content">
              {leaveType &&
                MYTEAMTabID &&
                map(teamLeave, (leaveDetails) => (
                  <StaffLeaveIndicatorInCalendar
                    key={leaveDetails.user_leave_id}
                    icon="team"
                    leaveDetails={leaveDetails}
                    isStaff={false}
                    reloadList={getLeaveList}
                    openEditLeaveModal={() => {
                      setLeaveDetails(leaveDetails);
                      toggleLeaveModal(true);
                    }}
                    EditLeaveModal={() => {
                      setLeaveDetails(leaveDetails);
                      toggleLeaveModal(true);
                    }}
                    displayLeaveDetail={AllTabID ? true : false}
                    Days={Days}
                  />
                ))}
            </div>
          </StyledDateCell>
          //Note : Need for Future
          // <StyledDateCell>
          //   <b>{day}</b>
          //   {leaveColor && (
          //     <StyledHolidayBadge
          //       status="success"
          //       size="small"
          //       color={leaveColor}
          //     >
          //       H
          //     </StyledHolidayBadge>
          //   )}
          //   <div className="ant-picker-calendar-date-content">
          //     {(leave.length > 0 || teamLeave.length > 0) &&
          //       MYTEAMTabID &&
          //       map(teamLeave, (leaveDetails) => (
          //         <StaffLeaveIndicatorInCalendar
          //           key={leaveDetails.user_leave_id}
          //           icon="team"
          //           leaveDetails={leaveDetails}
          //           isStaff={false}
          //           reloadList={getLeaveList}
          //           openEditLeaveModal={() => {
          //             setLeaveDetails(leaveDetails);
          //             toggleLeaveModal(true);
          //           }}
          //           EditLeaveModal={() => {
          //             setLeaveDetails(leaveDetails);
          //             toggleLeaveModal(true);
          //           }}
          //           displayLeaveDetail={AllTabID ? true : false}
          //           Days={Days}
          //         />
          //       ))}
          //   </div>
          // </StyledDateCell>
        )}

        {AllTabID && (
          <StyledDateCell>
            <b>{day}</b>
            {leaveColor && (
              <StyledHolidayBadge
                status="success"
                size="small"
                color={leaveColor}
              >
                H
              </StyledHolidayBadge>
            )}
            <div className="ant-picker-calendar-date-content">
              {AllTabID && (
                <>
                  <div className="hr-leave-container">
                    <Badge
                      count={hrPendingLeaves}
                      className="hr-has-pending-leaves"
                      onClick={() => {
                        setLeaveListFiterForHR({
                          date: cellDate,
                          status_id: "4",
                        });
                        toggleLeaveListModalVisible(true);
                      }}
                    />
                    <Badge
                      count={hrApprovedLeaves}
                      className="hr-has-approved-leaves"
                      onClick={() => {
                        setLeaveListFiterForHR({
                          date: cellDate,
                          status_id: "5",
                        });
                        toggleLeaveListModalVisible(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </StyledDateCell>
        )}
      </>
    );
  };

  const cancelLeaveRequest = (details) => {
    const {
      user_leave_id,
      start_date: leave_start_date,
      end_date: leave_end_date,
      status_id,
    } = details;
    if (userRoleId !== 1 && status_id !== "4") {
      Modal.confirm({
        icon: "",
        content: "Are you sure you want to cancel your leave application?",
        okText: "YES",
        cancelText: "No",
        onOk: () => {
          return new Promise(async (resolve) => {
            const { code } = await CALL_API(
              `approve-reject-cancel-leave/${ClientId}`,
              "post",
              {
                user_leave_id,
                status_id: "9",
                is_cancel_request: false,
                start_date: getMySqlDate(leave_start_date),
                end_date: getMySqlDate(leave_end_date),
              }
            );
            if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
              message.error(`Oops!! something went wrong.`);
            } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
              message.error(`Invalid payload. Please enter correct data.`);
            } else if (code === STATUS_CODE.RECORD_EXIST) {
              message.error(`Leave aleady exist.`);
            } else if (code === STATUS_CODE.SUCCESS) {
              message.success(`Your leave application has been cancelled.`);
              getLeaveList();
            }
            resolve();
          }).catch((err) => console.log("[cancelLeaveRequest] Error -->", err));
        },
      });
    } else {
      setLeaveDetails(details);
      toggleLeaveModal(true);
    }
  };

  const cancelLeave = (details) => {
    const {
      user_leave_id,
      start_date: leave_start_date,
      end_date: leave_end_date,
      status_id,
    } = details;
    if (userRoleId !== 1 && status_id === "4") {
      Modal.confirm({
        icon: "",
        content: "Are you sure you want to cancel your leave application?",
        okText: "YES",
        cancelText: "No",
        onOk: () => {
          return new Promise(async (resolve) => {
            const { code } = await CALL_API(
              `approve-reject-cancel-leave/${ClientId}`,
              "post",
              {
                user_leave_id,
                status_id: "9",
                is_cancel_request: false,
                start_date: getMySqlDate(leave_start_date),
                end_date: getMySqlDate(leave_end_date),
              }
            );
            if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
              message.error(`Oops!! something went wrong.`);
            } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
              message.error(`Invalid payload. Please enter correct data.`);
            } else if (code === STATUS_CODE.RECORD_EXIST) {
              message.error(`Leave aleady exist.`);
            } else if (code === STATUS_CODE.SUCCESS) {
              message.success(`Your leave application has been cancelled.`);
              getLeaveList();
            }
            resolve();
          }).catch((err) => console.log("[cancelLeave] Error-->", err));
        },
      });
    } else {
      setLeaveDetails(details);
      toggleLeaveModal(true);
    }
  };

  const leaveCalendarContent = (
    <>
      <Row gutter={20}>
        <Col span={4}>
          <StyledLeaveCount color={COLORS.PENDING_LEAVE} />
        </Col>
        <Col span={20}>
          <StyledLeaveText>
            Pending Leaves{includes([1, 4], userRoleId) ? "" : " count"}
          </StyledLeaveText>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={4}>
          <StyledLeaveCount color={COLORS.APPROVED_LEAVE} />
        </Col>
        <Col span={20}>
          <StyledLeaveText>
            Approved Leaves{includes([1, 4], userRoleId) ? "" : " count"}
          </StyledLeaveText>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <TabsList
        items={items}
        handleTabSelect={handleTabSelect}
        tabBarExtraContent={tabBarExtraContent}
        tabId={tabId}
      />
      {MYTabID && <LeaveWeekCalendar />}
      {!MYTabID && (
        <ReusableCalendar
          dateFullCellRender={dateFullCellRender}
          isCalendarLoading={displayLoader}
          calendarTitle={MYTabID ? "My Calendar" : "Calendar"}
          leftPanelContent={leaveCalendarContent}
        />
      )}

      <LeaveForm
        isModalVisible={displayLeaveModal}
        closeModal={() => toggleLeaveModal(false)}
        leaveDetails={leaveDetails}
        getLeaveList={getLeaveList}
      />
      <LeaveList
        isModalVisible={isLeaveListModalVisible}
        closeModal={() => toggleLeaveListModalVisible(false)}
        date={get(leaveListFiterForHR, "date", "")}
        status_id={get(leaveListFiterForHR, "status_id", "")}
        department_id={departmentSelectedByHR}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(LeaveCalendar);
