import React, { useEffect, useState, useRef } from "react";
import { Line } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin, Progress, List } from "antd";
import { get, find, map, size, filter, reduce, result } from "lodash";
import { MONTH_LIST, CHECKIN_REPORT_FILTER_TYPES } from "common/Constants";
import {
  addDaysinDate,
  dateInDetail,
  getMonthDateRange,
  getKenyanDateTime,
  addMonthinDate,
  getMySqlDate,
  momentDate,
  getinitialdate,
} from "utils/Date";
import {
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Form,
  Tooltip,
  message,
  Button,
} from "antd";
import moment from "moment";
import { DEVICE } from "common/Device";
import { CALL_API } from "common/API";
import { CSVLink } from "react-csv";
import {
  getUserInfo,
  getUserList,
  getIsCheckedIn,
  getClientid,
} from "redux/selectors";
import { connect } from "react-redux";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  /* min-height: 280px; */
  height: 100%;
  padding: 15px;
  box-shadow: 0px 4px 30px 0px #0000001f;
  border-radius: 8px;
  /* margin-bottom: 25px; */
  @media (max-width: 321px) {
    padding: 0px;
  }
  .list-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 426px) {
      font-size: 10px;
    }
  }
  .name-title {
    font-size: 14px;
    padding-bottom: 15px;
  }
  .dash-line {
    width: 60%;
    border-bottom: 1px dashed #a8a8a8;
    @media (max-width: 321px) {
      width: 2%;
    }
  }
  .title {
    padding: 10px;
    font-weight: bold;
  }
  .report_list {
    height: calc(100% - 20px);
    overflow: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .list_container {
    height: 165px;
    margin-top: 5px;
  }
  .list_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .average {
    color: blue;
  }
  .page-title-head{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${DEVICE.tablet} {
    .dash-line {
      width: 20%;
      border-bottom: 1px dashed #a8a8a8;
    }
    .name-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 15px;
    }
    .name-title p {
      text-align: right;
      @media (max-width: 321px) {
        padding-right: 5px;
      }
    }
  }
`;
const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-form-item {
    margin-bottom: 5px;
    @media (max-width: 321px) {
      margin-top: 5px;
    }
  }
  .ant-btn {
    height: 29px;
    margin-top: 2px;
    margin-left: 0px;
    padding: 0px 10px 2px 10px;
  }
`;

const StyledButton3 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  border-radius: 5px;
  @media (max-width: 321px) {
    margin-right: 10px;
  }
`;
const StyleRow = styled(Row)`
  margin-top: 10px;
`;

function UserCheckinReport({ userInfo, userList, isCheckedIn, ClientId }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [checkinDetail, setCheckinDetail] = useState([]);
  const [avg, setAvg] = useState(0);
  const [ontime, setOntime] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [monthList, setMonthList] = useState([]);

  const [CSVcheckinList, setCSVcheckinList] = useState([]);
  const csvLinkEl = useRef();
  const shiftStartTime = result(
    find(userList, { user_id: userInfo?.employer_id, role_id: 4 }),
    "shift_start_time"
  );

  const CSVHeaders = [{ label: "Date" }, { label: "Check-in time" }];

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };
  useEffect(() => {
    list();
    getCheckinList();
  }, [isCheckedIn, ClientId]);

  const getCheckinList = async () => {
    return new Promise(async (resolve, reject) => {
      const {
        // start_date,
        duration_type,
        month,
        // end_date,
      } = form.getFieldsValue();

      const start_date = form.getFieldValue("start_date");
      const end_date = form.getFieldValue("end_date");
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(
          find(MONTH_LIST, { value: form.getFieldValue("month") }),
          "index",
          0
        )
      );
      setLoading(true);
      if (ClientId) {
        const { checkInDetail } = await CALL_API(
          `user-login-details/${ClientId}`,
          "post",
          {
            start_date: getMySqlDate(
              duration_type === 2
                ? startDateOfMonth
                : duration_type === 4
                ? momentDate(addMonthinDate(getinitialdate()))
                : start_date
            ),
            end_date: getMySqlDate(
              duration_type === 2
                ? lastDateOfMonth
                : duration_type === 3
                ? weeks(7, getMySqlDate(start_date))
                : duration_type === 4
                ? momentDate(addMonthinDate(getKenyanDateTime(), 3))
                : end_date
            ),
          }
        );
        setCheckinDetail(checkInDetail);
        let total = size(checkInDetail);
        const ontimeCheckins = filter(checkInDetail, {
          punchinStatus: "ontime",
        }).length;
        // map(checkIn.checkInDetail, (item) =>{
        //     if (moment(item.checkinTime).format('HH:mm') < '08:15'){
        //     ontimeCount ++
        //     }
        // })
        setTotalDays(total);
        setOntime(ontimeCheckins);
        let average = Math.floor((ontimeCheckins * 100) / total);
        setAvg(average ? average : 0);
        setLoading(false);
      }
    });
  };

  const validateDateRange = (date) => {
    const initialdate = getinitialdate();
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
    getCheckinList();
  };

  function weeks(days, date) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date.toISOString().slice(0, 10);
  }

  const containerRef = useRef(null);

  const prepareCSV = async (event, done) => {
    if (checkinDetail.length > 0) {
      setCSVcheckinList(
        reduce(
          checkinDetail,
          function (result, record) {
            result.push({
              Date: `${moment(record?.checkinDate).format("Do MMM YYYY")}`,
              Day: `${moment(record?.checkinDate).format("dddd")}`,
              "Check-in time": `${record?.checkinTime}`,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const [durationsType, setDurationsType] = useState(2)

  console.log("duration_type ====>", durationsType)

  return (
    <StyledChartContainer>
      <StyledTitle>CHECK IN REPORT</StyledTitle>
      <div className="name-title">
        <Col
          xs={5}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="vertical"
              onChange={getCheckinList}
              size="medium"
              initialValues={{
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
              }}
              onFinish={getCheckinList}
            >
              <Row gutter={4} className={durationsType === 1 && "page-title-head"}>
                <Col className={durationsType === 1 && "w-100"}>
                  <Form.Item
                    name="duration_type"
                    className={durationsType === 1 && "w-100"}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select..."
                      style={{
                        width: 140,
                      }}
                      options={CHECKIN_REPORT_FILTER_TYPES}
                      onChange={getCheckinList}
                      getPopupContainer={() => containerRef.current}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12}>
                  <Form.Item className="outer-form" shouldUpdate>
                    {({ getFieldValue }) => {
                      const duration_type = getFieldValue("duration_type");
                      setDurationsType(duration_type);
                      return duration_type === 1 ? (
                        <div className="d-flex justify-content-start align-items-center gap-2">
                          <Form.Item
                            name="start_date"
                            rules={[
                              () => ({
                                validator(rule, value) {
                                  const date = moment(value);
                                  return date.isValid()
                                    ? Promise.resolve()
                                    : Promise.reject();
                                },
                              }),
                            ]}
                            ref={containerRef}
                          >
                            <DatePicker
                              style={{
                                width: 140,
                              }}
                              placeholder="Start date"
                              format="DD-MM-YYYY"
                              // disabledDate={(current) => restrictPastDate(current, 'start_date')}
                              onChange={validateDateRange}
                              allowClear={false}
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>

                          <Form.Item
                            name="end_date"
                            rules={[
                              () => ({
                                validator(rule, value) {
                                  const date = moment(value);
                                  return date.isValid()
                                    ? Promise.resolve()
                                    : Promise.reject();
                                },
                              }),
                            ]}
                            ref={containerRef}
                          >
                            <DatePicker
                              style={{
                                width: 140,
                              }}
                              format="DD-MM-YYYY"
                              placeholder="End date"
                              onChange={validateDateRange}
                              allowClear={false}
                              getPopupContainer={() => containerRef.current}
                              // disabled={isViewOnly}
                            />
                          </Form.Item>
                        </div>
                      ) : duration_type === 3 ? (
                        <Form.Item
                          name="start_date"
                          rules={[
                            () => ({
                              validator(rule, value) {
                                const date = moment(value);
                                return date.isValid()
                                  ? Promise.resolve()
                                  : Promise.reject();
                              },
                            }),
                          ]}
                          ref={containerRef}
                        >
                          <DatePicker
                            style={{
                              width: 172,
                            }}
                            placeholder="Start date"
                            format="DD-MM-YYYY"
                            onChange={validateDateRange}
                            allowClear={false}
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      ) : duration_type === 2 ? (
                        <Form.Item
                          name="month"
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 172,
                            }}
                            placeholder="Select..."
                            options={monthList}
                            onChange={getCheckinList}
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <div>
          Average on-time rate{" "}
          <span style={{ color: "#000", fontWeight: "bold" }}>{avg}%</span>
        </div>
      </div>
      <Spin spinning={loading}>
        <Col md={24} sm={24} xs={24} xl={24}>
          <Tooltip
            placement="bottom"
            title={`${ontime}/${totalDays} days on-time`}
          >
            <Progress
              percent={avg}
              strokeColor={"#A33CFC"}
              trailColor={"#D5A5FF"}
              strokeWidth={15}
              showInfo={false}
            />
          </Tooltip>
        </Col>
        <div>
          <StyleRow>
            <Col md={12} sm={12} xs={12} lg={12} xl={12} className="title">
              Check-in Time
            </Col>
            <Col xs={24} sm={12} md={12} className="align-right">
              <ButtonOutlined
                onClick={prepareCSV}
                disabled={checkinDetail?.length === (0 || null || undefined)}
              >
                Export CSV file
              </ButtonOutlined>
              <CSVLink
                header={CSVHeaders}
                data={CSVcheckinList}
                filename={"check-in report.csv"}
                asyncOnClick={true}
                ref={csvLinkEl}
              />
            </Col>
          </StyleRow>
          <Col md={24} sm={24} xs={24} xl={24} className="list_container">
            <List
              size="small"
              bordered={false}
              dataSource={checkinDetail}
              renderItem={(item) => (
                <List.Item className="list-item">
                  {moment(item.checkindate).format("DD-MM-YYYY")}
                  <span className="dash-line" />
                  {moment(item.checkintime, "HH:mm:ss").format("hh:mm A")}
                </List.Item>
              )}
              style={{
                height: "calc(100% - 20px)",
                overflow: "auto",
                marginTopBottom: "1%",
              }}
            />
          </Col>
        </div>
      </Spin>
    </StyledChartContainer>
  );
}

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  userList: getUserList(state),
  isCheckedIn: getIsCheckedIn(state),
  ClientId: getClientid(state),
});

export default connect(mapStateToProps)(UserCheckinReport);
