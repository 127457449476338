import React, { useEffect, useLayoutEffect, useState } from "react";
import { Layout, Row } from "antd";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Modal, Result, Button } from "antd";
import { FieldTimeOutlined } from "@ant-design/icons";
import styled from "styled-components";
// Components
import SideBar from "molecules/SideMenu";
import Header from "molecules/Header";
import Container from "pages/Container";
import { STATUS_CODE } from "common/Constants";
// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setIsUserLoggedIn,
  setSystemDate,
  setUsernName,
  setLoadingDataFirstTime,
  setBreakTypesList,
  setDepartmentList,
  setLeaveCategoryList,
  setLocationList,
  setPunchActions,
  setUserRole,
  setDepartmentId,
  setRoleList,
  setCheckedIn,
  setCheckInLocationId,
  setIsOnBreak,
  setUserList,
  setStatusList,
  setAllDepartmentList,
  setPunchId,
  setBreakId,
  toggleProcessingModal,
  setShiftTimeMaster,
  setUserProfile,
  clearStore,
  setProfilePic,
  setMenuList,
  setLevelList,
  setClientid,
  setCurrentPlan,
  setTrailPeriod,
  setUserMenuList,
  setCheckInDepartmentId,
  setBandList,
  setSupervisorList,
  setActiveMenuList,
  setLoadingRoute,
  setStatusCodes,
  setCRMManagingOfficeList
} from "redux/actions";
import {
  getIsUserLoggedIn,
  getUserName,
  getSysteDate,
  getUserRoleId,
  getTrailPeriod,
  getUserInfo,
  getMenuList,
  getLoadingDataFirstTime,
  getUserList,
} from "redux/selectors";
import ProcessingModal from "molecules/ProcessingModal";
import { CALL_API } from "common/API";
import { getServerDateToMySql } from "utils/Date";
import PrivacyAndPolicy from "pages/PrivacyAndPolicy";
import { jwtDecode } from "jwt-decode";
import AppSideBar from "molecules/AppSideBar";
import FormResponse from "pages/FormResponse";
import { find } from "lodash";
import Login from "pages/Login";
import UserManual from "pages/UserManual";
import COLORS from "common/Colors";

var createHost = require("cross-domain-storage/host");

const StyledAppDiv = styled.div`
  height: 100%;
`;
const PlanWrap = styled(Row)`
  background-color: #d32f2f;
  height: 30px;
  padding: 5px;
  box-shadow: 5px 5px 10px #00000029;
  cursor: pointer;
  .title {
    font-weight: bold;
    color: #fff;
  }
  .subtitle {
    font-weight: bold;
    color: #ffff00;
  }
  .black-title {
    color: #000000;
  }
`;

const App = ({
  activePage,
  isUserLoggedIn,
  setUserLoggedIn,
  saveSystemDate,
  setBreakTypes,
  saveDepartmentList,
  saveAllDepartmentList,
  saveLeaveCategoryList,
  saveLocationList,
  savePunchActions,
  saveUsernName,
  toggleLoadingDataFirstTime,
  saveDepartmentId,
  saveRoleList,
  updateCheckInLocationId,
  updateIsOnBreak,
  updateCheckedIn,
  updateUserList,
  saveStatusList,
  savePunchId,
  saveBreakId,
  updateProcessingModal,
  saveShiftTimeMaster,
  saveUserProfile,
  removeStoreData,
  saveProfilePic,
  saveUserRole,
  saveMenuList,
  saveBandList,
  saveClientid,
  saveTrailPeriod,
  saveCurrentPlan,
  userRoleId,
  TrailPeriod,
  saveUserMenuList,
  updateCheckInDepartmentId,
  menuList,
  saveActiveMenuList,
  saveSupervisorList,
  saveLevelList,
  isLoadingDataFirstTime,
  loadingRoute,
  saveStatusCodes,
  saveCRMManagingOfficeList
}) => {
  createHost([
    {
      origin: "http://localhost:3000",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.tractask.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.crm.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001/",
      allowedMethods: ["get", "set", "remove"],
    },
  ]);
  
  const [pageTitle, setPageTitle] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [intervalID, setIntervalID] = useState(0);
  const [open, setOpen] = useState(false);
  const [showplan, setshowplan] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuLists, setMenuLists] = useState([]);
  const [isRecruitment, setIsRecruitment] = useState(false);

  const userDataList = useSelector((state) => getUserList(state)) || [];

  // const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };

  const isLoggedIn = () => {
    setUserLoggedIn(!isUserLoggedIn);
    toggleLoadingDataFirstTime(true);
    fetchMasterUserData(true);
    // setTimeout(() => {
    //   toggleLoadingDataFirstTime(false);
    // }, 500);
    fetchMasterData(true);
    getPreview();
  };

  const isLoggedOut = () => {
    // setUserLoggedIn(true);
    toggleLoadingDataFirstTime(true);
    setUserLoggedIn(false);
    sessionStorage.removeItem("tokenTimestamp");
  };

  const logOut = () => {
    window.localStorage.removeItem("token");
    isLoggedOut();
    setSessionTimeout(false);
  };

  const updatePageTitle = (title) => {
    setPageTitle(title);
  };

  let defaultKey = "";

  useEffect(() => {
    if (isUserLoggedIn) {
      const intId = setInterval(() => {
        saveSystemDate(startTimeCounter());
      }, 1000);
      setIntervalID(intId);
      setUserLoggedIn(true);
      fetchMasterUserData(true);
      fetchMasterData(true);
    } else {
      clearInterval(intervalID);
    }
  }, []);

  useLayoutEffect(() => {
    clearInterval(intervalID);
    updateProcessingModal(false);
    if (window.innerWidth < 567) {
      toggleDrawer();
    }
  }, []);

  const redirectToNewDomain = () => {
    window.location.href = "https://www.tymeplushr.com/";
  };

  // localtoken is used For local development

  // const localtoken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IldBOE1JWVQiLCJ1c2VySWQiOiJzaXZhc21rNTNAZ21haWwuY29tIiwidXNlck5hbWUiOiJTaXZhIiwiaWF0IjoxNzExNTEwMDA4LCJleHAiOjE3MTE1MjgwMDh9.VAurlcnviSeoJ-MvUULzFOsDzCUKF156UvD_V9OqULU";

  // useEffect(() => {
  //   window.localStorage.setItem("token", localtoken);
  //   sessionStorage.setItem('tokenTimestamp', new Date().getTime().toString());
  //   getPreview()
  //   const clientid = jwtDecode(window.localStorage.getItem("token"))?.clientId;
  //   isLoggedIn(clientid);
  //   setUserLoggedIn(true)
  // }, []);

  const urlParts = window.location.pathname.split("/");
  const clientIdFromUrl = urlParts[2];
  const idFromUrl = urlParts[3];
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (urlParts[1] === "form" && clientIdFromUrl && idFromUrl) {
          console.log("Form response");
          setIsRecruitment(true);
          setUserLoggedIn(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      const token = window.localStorage.getItem("token");
      const decodedToken = token ? jwtDecode(token) : null;
      if (!decodedToken || !decodedToken.exp) return;

      const currentTime = new Date().getTime();
      const expirationTime = decodedToken.exp * 1000; // exp is in seconds, convert to milliseconds

      if (currentTime > expirationTime) {
        setSessionTimeout(true);
      }
    };

    // Initial check
    checkTokenExpiration();

    // Set interval for periodic check
    const intervalId = setInterval(checkTokenExpiration, 60000); // Check every 1 minute

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // const startTimeCounter = () => {
  //   // Get the user's current time zone
  //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   // Create a new Date object with the user's time zone
  //   const d = new Date();
  //   const ist = new Date(
  //     d.toLocaleString("en-US", { timeZone: userTimeZone })
  //   );

  //   return getServerDateToMySql(ist);
  // };

  const startTimeCounter = () => {
    // Create a new Date object with the Kenya time zone
    const d = new Date();
    const kenyaTime = new Date(
      d.toLocaleString("en-US", { timeZone: "Africa/Nairobi" })
    );

    return getServerDateToMySql(kenyaTime);
  };

  const fetchMasterUserData = async (fetch_master_userdata) => {
    const token = window.localStorage.getItem("token");
    if (!token) {
      setUserLoggedIn(false);
      return;
    }

    const clientId = jwtDecode(token)?.clientId;
    const {
      code,
      role_id,
      menuList,
      clientid
    } = await CALL_API(`master-data/userdetails?clientid=${clientId}`, "GET", {
      fetch_master_userdata,
    });

    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_userdata) {

        const homeMenuList = menuList[role_id[0]]?.filter(
          (menu) => menu.subMenu.length === 0 && menu.key !== "managementview"
        );

        saveActiveMenuList({
          [userRoleId]: homeMenuList,
        });

        const modifiedMenuList = menuList[role_id[0]].map((menu) => {
          if (menu.subMenu.length > 0) {
            return { ...menu, active: false };
          }
          return menu;
        });

        saveMenuList({ [role_id[0]]: modifiedMenuList });

        setMenuLists({
          [role_id[0]]: modifiedMenuList,
        });;
      }

      saveUserRole(role_id[0]);
      saveClientid(clientid);
      toggleLoadingDataFirstTime(false);
      
      // if (punch_action_id) {
      //   window.localStorage.setItem("login_reminder", "true");
      // }
    } else {
      setUserLoggedIn(false);
      // setSessionTimeout(true);
    }
  };


  const fetchMasterData = async (fetch_master_data) => {
    if (Array.isArray(userDataList) && userDataList?.length === 0) {
      loadingRoute(true);
    }
    const token = window.localStorage.getItem("token");
    if (!token) {
      setUserLoggedIn(false);
      return;
    }

    const clientId = jwtDecode(token)?.clientId;
    const {
      code,
      name,
      userid,
      baselocationid,
      role_id,
      breakList,
      roleList,
      departmentList,
      leaveCategories,
      locations,
      punchActions,
      location_id,
      punch_action_id,
      userList,
      statusList,
      shiftTimeMaster,
      break_id = "",
      department_id,
      location_name,
      joiningdate,
      username,
      email_id,
      job_title,
      department,
      payroll,
      staff_department_id,
      menuList,
      bandList,
      clientid,
      trailperiodendon,
      currentPlan,
      userMenuList,
      threadid,
      supervisorList,
      levelList,
      statusCodes,
      managingOfficeList
    } = await CALL_API(`master-data/${clientId}`, "post", {
      fetch_master_data,
    });

    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        const setRoleList = find(roleList, { roleId: role_id[0] });
        setBreakTypes(breakList);

        const homeMenuList = menuList[role_id[0]]?.filter(
          (menu) => menu.subMenu.length === 0 && menu.key !== "managementview"
        );

        saveActiveMenuList({
          [userRoleId]: homeMenuList,
        });

        const modifiedMenuList = menuList[role_id[0]].map((menu) => {
          if (menu.subMenu.length > 0) {
            return { ...menu, active: false };
          }
          return menu;
        });

        saveMenuList({ [role_id[0]]: modifiedMenuList });

        setMenuLists({
          [role_id[0]]: modifiedMenuList,
        });
        saveBandList(bandList);
        saveUserMenuList(userMenuList);
        saveDepartmentList(setRoleList?.department);
        saveAllDepartmentList(departmentList);
        saveDepartmentId(setRoleList?.department);
        saveLeaveCategoryList(leaveCategories);
        saveLocationList(locations);
        saveTrailPeriod(trailperiodendon);
        saveCurrentPlan(currentPlan);
        saveClientid(clientid);
        savePunchActions(punchActions);
        saveUsernName(name);
        saveRoleList(roleList);
        updateUserList(userList);
        saveStatusList(statusList);
        saveShiftTimeMaster(shiftTimeMaster);
        saveLevelList(levelList);
        saveUserProfile({
          location_name,
          joiningdate,
          username,
          email_id,
          job_title,
          department,
          payroll,
          userid,
          staff_department_id,
          threadid,
          role_id,
          baselocationid,
        });
      }

      updateCheckInLocationId(location_id?.toString());
      updateCheckInDepartmentId(department_id?.toString());
      updateIsOnBreak(break_id ? true : false);
      saveBreakId(break_id);
      savePunchId(punch_action_id);
      saveUserRole(role_id[0]);
      updateCheckedIn(punch_action_id ? true : false);
      saveSupervisorList(supervisorList);
      loadingRoute(false);
      saveStatusCodes(statusCodes);
      saveCRMManagingOfficeList(managingOfficeList)

      if (punch_action_id) {
        window.localStorage.setItem("login_reminder", "true");
      }
    } else {
      setUserLoggedIn(false);
      loadingRoute(false);
      // setSessionTimeout(true);
    }
  };

  const getPreview = async () => {
    const token = window.localStorage.getItem("token");

    const clientId = jwtDecode(token)?.clientId;

    const { code, url } = await CALL_API(
      `profile-upload/${clientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      saveProfilePic(url);
    }
  };

  const currentPath = window.location.pathname;
  const isUserManual = currentPath === "/usermanual";

  return (
    <StyledAppDiv>
      <Router>
        <Route path="/privacy-policy">
          <PrivacyAndPolicy />
        </Route>
        <Route path="/form/:clientId/:id">
          <FormResponse />
        </Route>
        <Route path="/usermanual">
          <UserManual />
        </Route>
      </Router>

      {isUserLoggedIn && !isUserManual && (
        <>
          <Router>
            <Layout isapp={`${navigator.userAgent === "application"}`}>
              {!isLoadingDataFirstTime && (
                <Layout.Sider
                  width={70}
                  style={{
                    background: COLORS.PRIMARY,
                  }}
                >
                  <AppSideBar open={drawerOpen} setOpen={setDrawerOpen} />
                </Layout.Sider>
              )}
              <SideBar
                open={open}
                onClose={onClose}
                setPageTitle={updatePageTitle}
                defaultKey={defaultKey}
                activePage={activePage}
                collapsed={collapsed}
                toggleCollapse={toggleDrawer}
              ></SideBar>
              <Layout>
                <Header
                  showDrawer={showDrawer}
                  pageTitle={pageTitle}
                  setLoggedIn={isLoggedIn}
                  setPageTitle={updatePageTitle}
                  toggleCollapse={toggleDrawer}
                  collapsed={collapsed}
                  showplan={showplan}
                  setshowplan={setshowplan}
                  isLoggedOut={isLoggedOut}
                ></Header>
                {userRoleId === 1 && TrailPeriod && (
                  <PlanWrap onClick={() => setshowplan(true)}>
                    <marquee
                      className="title"
                      width="100%"
                      direction="left"
                      height="100px"
                    >
                      Your Free Trail Period Ends on{" "}
                      <span className="subtitle">{TrailPeriod}</span> Please{" "}
                      <span className="black-title">Upgrade</span> Your Plan
                    </marquee>
                  </PlanWrap>
                )}
                {/* {userRoleId !== 1 &&
                  window.innerWidth < 567 &&
                  Menulist &&
                  Menulist?.find(
                    (item) => item?.key === "managementview" && item?.access
                  ) && (
                    <div className="bg-white text-end px-4 pt-2 pb-2">
                      <ArrowCircleLeftIcon
                        style={iconcolor}
                        onClick={() => setDrawerOpen(true)}
                      />
                    </div>
                  )} */}

                <Container menuLists={menuLists} appBarWidth={"0"} />
              </Layout>
            </Layout>
          </Router>
        </>
      )}

      {!isUserLoggedIn && !isRecruitment && !isUserManual && (
        <Login setLoggedIn={isLoggedIn} />
      )}

      <ProcessingModal />

      <Modal
        visible={sessionTimeout}
        maskClosable={false}
        title={null}
        footer={null}
        closable={false}
      >
        <Result
          icon={<FieldTimeOutlined />}
          title="Your session has expired!"
          extra={
            <Button onClick={() => logOut()} type="primary">
              Login
            </Button>
          }
        />
      </Modal>
    </StyledAppDiv>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: getIsUserLoggedIn(state),
  userName: getUserName(state),
  systeDate: getSysteDate(state),
  userRoleId: getUserRoleId(state),
  TrailPeriod: getTrailPeriod(state),
  userInfo: getUserInfo(state),
  menuList: getMenuList(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserLoggedIn: setIsUserLoggedIn,
      saveSystemDate: setSystemDate,
      saveUsernName: setUsernName,
      setBreakTypes: setBreakTypesList,
      saveDepartmentList: setDepartmentList,
      saveSupervisorList: setSupervisorList,
      saveAllDepartmentList: setAllDepartmentList,
      saveLeaveCategoryList: setLeaveCategoryList,
      saveLocationList: setLocationList,
      savePunchActions: setPunchActions,
      saveUserRole: setUserRole,
      toggleLoadingDataFirstTime: setLoadingDataFirstTime,
      loadingRoute: setLoadingRoute,
      saveDepartmentId: setDepartmentId,
      saveRoleList: setRoleList,
      updateCheckedIn: setCheckedIn,
      updateCheckInLocationId: setCheckInLocationId,
      updateIsOnBreak: setIsOnBreak,
      updateUserList: setUserList,
      saveStatusList: setStatusList,
      savePunchId: setPunchId,
      saveBreakId: setBreakId,
      updateProcessingModal: toggleProcessingModal,
      saveShiftTimeMaster: setShiftTimeMaster,
      saveUserProfile: setUserProfile,
      removeStoreData: clearStore,
      saveProfilePic: setProfilePic,
      saveMenuList: setMenuList,
      saveBandList: setBandList,
      saveUserMenuList: setUserMenuList,
      saveActiveMenuList: setActiveMenuList,
      saveClientid: setClientid,
      saveCurrentPlan: setCurrentPlan,
      saveTrailPeriod: setTrailPeriod,
      updateCheckInDepartmentId: setCheckInDepartmentId,
      saveLevelList: setLevelList,
      saveStatusCodes: setStatusCodes,
      saveCRMManagingOfficeList: setCRMManagingOfficeList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
