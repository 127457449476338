import { Button, Card, Form, Input, Select, Spin, Upload } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllDepartmentList,
  getCompetencyQc,
  getlevelList,
  getLocations,
  getManagingOffice,
  getServiceLine,
  getTempEngagementFormData,
  getUserInfo,
  getUserList,
  levelList,
} from "redux/selectors";
import { competencies } from "./optionsData";
import { UploadOutlined } from "@ant-design/icons";
import { useScrollFetch } from "hooks/useScrollFetch";
import queryKeys from "common/queryKey";
import { Required } from "./index.js";
import { useFetch } from "hooks/useFetch";
import { useQueryState } from "hooks/useQueryState";
import { MONTH_LIST } from "common/Constants";
import { filter, find, flatMap, isEmpty, uniqBy } from "lodash";
import { CALL_FEATCH_API } from "common/API";
import { displayErrorModal, displayWarningModal } from "common/common";

const labelStyle = {
  width: "175px",
  display: "inline-block",
  textAlign: "start",
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

const handleKeyDown = (event) => {
  // Allow: backspace, tab, enter, escape, and space
  if (
    event.key === "Backspace" ||
    event.key === "Tab" ||
    event.key === "Enter" ||
    event.key === "Escape" ||
    event.key === " "
  ) {
    return; // allow these keys
  }

  // Allow: Ctrl+C, Ctrl+V, Ctrl+X
  if (
    (event.ctrlKey || event.metaKey) &&
    (event.key === "c" || event.key === "v" || event.key === "x")
  ) {
    return; // allow copy/paste/cut
  }

  // Regex for allowed characters
  const regex = /^[a-zA-Z0-9 _-]*$/;

  // Check if the key pressed matches the regex
  if (!regex.test(event.key)) {
    event.preventDefault(); // prevent the default action if not allowed
  }
};

const ClientFields = ({
  handleUploadDocument,
  isDocumentLoading,
  engagementID = "",
  engagementData = {},
  update,
  levelList,
  disabled,
}) => {
  const containerRef = useRef(null);
  const form = Form.useFormInstance();
  const departmentList = useSelector((state) => getAllDepartmentList(state));

  // const [loeDocumentFileList, setLoeDocumentFileList] = useState([]);
  // const [coiDocumentFileList, setCoiDocumentFileList] = useState([]);
  const manageoffice = useSelector((state) => getManagingOffice(state));
  const competencyList = useSelector((state) => getCompetencyQc(state));
  const userInfo = useSelector((state) => getUserInfo(state));
  const userList = useSelector((state) => getUserList(state));

  const partnerCountry = userList?.find(
    (user) => user.userid === userInfo?.userid
  )?.managingoffice[0];

  // const CompetencyData = [
  //   {
  //     label: "Audit & Assurance",
  //     value: "Audit & Assurance",
  //     serviceline: [
  //       "Audits (IFRS / Statutory Audits)",
  //       "Non-assurance engagements (AUP + Compilations)",
  //       "Other assurance engagements (project Audits)",
  //       "Reviews (Others)",
  //     ],
  //   },
  //   {
  //     label: "Tax",
  //     value: "Tax",
  //     serviceline: [
  //       "Tax advisory Services",
  //       "Tax Compliance and assurance Services",
  //     ],
  //   },
  //   {
  //     label: "Advisory /Consulting",
  //     value: "Advisory /Consulting",
  //     serviceline: [
  //       "Corporate Finance, Transactions, Restructuring & M&A",
  //       "Risk Management (RAS), Forensics Investigations, Risk, Regulatory & Compliance",
  //       "Management Consultancy (Strategy, HR, PM, Devt. Advisory)",
  //       "Technology Advisory, Cyber security, Analytics & Insights",
  //     ],
  //   },
  //   {
  //     label: "BSO",
  //     value: "BSO",
  //     serviceline: [
  //       "Accounting and Payroll Services",
  //       "Corporate secretarial and administrative services",
  //       "Business process outsourcing (FMA, BPO and other admin services)",
  //     ],
  //   },
  // ];

  const managinOfficeOptions = manageoffice.map((value) => {
    return {
      label: value,
      value: value,
    };
  });

  const departmentOptions = departmentList.map((department) => {
    return {
      label: department.department_name,
      value: department.department_id,
    };
  });

  const competencyOptions = competencyList.map((competency) => {
    return {
      label: competency,
      value: competency,
    };
  });

  const selectedCompetency = form.getFieldValue("client_competency");

  const { getAllClientsList } = queryKeys;

  const { key, url } = getAllClientsList;

  const {
    data: clientsDataRes,
    fetchNextPage,
    isLoading: loading,
    hasNextPage,
  } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
  });

  const clients = useMemo(() => {
    return clientsDataRes?.pages?.reduce((acc, page) => {
      if (page?.data === undefined) return acc;
      return [...acc, ...page?.data];
    }, []);
  }, [clientsDataRes]);

  const clientOptions = clients
    ?.filter(
      (client) =>
        client?.bdo_id !== "NULL" &&
        form
          ?.getFieldValue("managing_office")
          ?.includes(client?.bdo_managingoffice) &&
        client?.bdo_onboarded === "Yes"  
    )
    ?.map((client) => ({
      label: client?.registeredName,
      value: client?.clientId,
    }));

  const clientName = clientOptions?.find(
    //? will give result only after submitting Engagement
    (client) => client?.value === engagementData?.clientId
  )?.label;

  const onScroll = async (event) => {
    const target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      console.log("Loading...");
      target.scrollTo(0, target.scrollHeight);
      hasNextPage && fetchNextPage();
    }
  };
  const serviceLineList = [
    "Audits (IFRS / Statutory Audits)",
    "Non-assurance engagements (AUP + Compilations)",
    "Other assurance engagements (project Audits)",
    "Reviews (Others)",
    "Tax advisory Services",
    "Tax Compliance and assurance Services",
    "Corporate Finance, Transactions, Restructuring & M&A",
    "Risk Management (RAS), Forensics Investigations, Risk, Regulatory & Compliance",
    " Management Consultancy (Strategy, HR, PM, Devt. Advisory)",
    "Technology Advisory, Cyber security, Analytics & Insights",
    "Accounting and Payroll Services",
    "Corporate secretarial and administrative services",
    "Business process outsourcing (FMA, BPO and other admin services)",
  ];

  const serviceLineOptions = serviceLineList.map((serviceLine) => {
    return {
      label: serviceLine,
      value: serviceLine,
    };
  });

  // useEffect(() => {
  //   form.setFieldsValue({
  //     service_line: null,
  //   });
  // }, [form.getFieldValue("client_competency")]);

  const [managingOfficeData, setManagingOfficeData] = useState([]);
  const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  const [servicelineData, setServicelinefetchData] = useState([]);

  const competencyQCfetchData = async () => {
    const filtervalue = managingOfficeData.filter((item) =>
      form.getFieldValue("managing_office").includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level2&parentLevel=[${"BDO"}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      // setCompetencyQCfetchData(result);
      setCompetencyQCfetchData(
        result.filter((item) => {
          return (
            item.value == "Advisory" ||
            item.value == "Tax" ||
            item.value == "Audit" ||
            item.value == "Business Services & Outsourcing"
          );
        })
      );
    }
  };

  const servicelinefetchData = async () => {
    const filtervalue = competencyQCData.filter((item) =>
      form.getFieldValue("client_competency")?.includes(item.value)
    );
    const mapdata = filtervalue.map((item) => item?.name);
    const response = await CALL_FEATCH_API(
      `insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`,
      "get"
    );
    if (!isEmpty(response)) {
      const result = flatMap(response.data, (item) =>
        item.value.map((value) => ({
          name: item.name,
          value,
          parentLevel: item.parentLevel,
        }))
      );
      setServicelinefetchData(result);
      if (isEmpty(result)) {
        form.setFieldValue("service_line", []);
      } else {
        // serviceline
        const servicelinelevelList = filter(result, (item) =>
          form.getFieldValue("client_competency").includes(item.parentLevel)
        );
        const serviceline = filter(
          form.getFieldValue("service_line"),
          (value) => find(servicelinelevelList, { value: value })
        );
        form.setFieldValue("service_line", serviceline);
      }
    }
  };
  useEffect(() => {
    competencyQCfetchData();
  }, []);

  const handleChange = async (file, type) => {
    const actualFile = file.originFileObj || file.file;
    if (actualFile) {
      try {
        // Extract the actual File object
        const file1 = file.originFileObj || file.file;
        const base64 = await fileToBase64(file1);
        handleUploadDocument(file, base64, type);
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };
  return (
    <Card className="fields-card">
      <Form.Item
        label={
          <span style={labelStyle}>
            Engagement Id <Required />
          </span>
        }
        name="engagement_id"
        rules={[{ required: true, message: "Please Enter Engagement Id" }]}
      >
        <Input
          placeholder="Enter Engagement Id"
          disabled={engagementID !== "" ? true : false || disabled}
        />
      </Form.Item>
      <Form.Item
        label={<span style={labelStyle}>Proposal Number</span>}
        name="proposal_number"
      >
        <Input placeholder="Enter" disabled={disabled} />
      </Form.Item>
      <div ref={containerRef}>
        <Form.Item
          label={<span style={labelStyle}>Managing Office </span>}
          name="managing_office"
          rules={[
            { required: false, message: "Please select managing office!" },
          ]}
        >
          {/* <Input placeholder="Enter" disabled/> */}
          <Select
            placeholder="Select"
            options={managinOfficeOptions}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            getPopupContainer={() => containerRef.current}
            disabled={partnerCountry !== "Head Office" || disabled}
            // onChange={() => {
            //   competencyQCfetchData();
            // }}
          />
        </Form.Item>
      </div>
      {/* <Form.Item
        label={
          <span style={labelStyle}>
            Department <Required />
          </span>
        }
        name="department"
        rules={[{ required: true, message: "Please select department!" }]}
      >
        <Select
          placeholder="Select"
          // options={departmentOptions} 
          options={departmentOptions.sort((a, b) =>
            a.label.localeCompare(b.label)
          )}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item> */}

      <div className="d-flex align-items-start gap-2" ref={containerRef}>
        <Form.Item
          name="client"
          className="w-100"
          label={
            <span style={labelStyle}>
              Client <Required />
            </span>
          }
          rules={[{ required: true, message: "Please select client!" }]}
        >
          {engagementData?.clientId ? (
            <div className="client-name-id">
              <Spin spinning={loading}>
                <span className="client-name">{clientName}</span>
              </Spin>
              <span>{engagementData?.clientId}</span>
            </div>
          ) : (
           
              <Select
                allowClear={true}
                id="client"
                placeholder="Select"
                options={clientOptions}
                disabled={engagementData?.clientId ? true : false}
                onPopupScroll={onScroll}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownRender={menu => (
                  <>
                    {loading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', padding: 8 }}>
                        <Spin />
                      </div>
                    ) : menu}
                  </>
                )}
                onChange={(val) => {
                  let client_details = clients?.find(
                    (item) => item?.clientId === val
                  );
                  form.setFieldsValue({
                    country: client_details.bdo_countryvalue,
                    classification: client_details.bdo_riskclassification,
                    client_name: client_details.registeredName,
                  });

                  if (client_details?.bdo_onboarded === "No") {
                    displayWarningModal(
                      "Please note, this client had not been onboarded, please finalize on the client onboarding procedures."
                    );
                  }

                  else if (!client_details?.primary_contact) {
                    displayWarningModal(
                      "Please note, this customer does not have contact person details. Kindly update the client's information in the CRM, otherwise, it will not allow the billing section to be added due to mandatory fields."
                    );
                  }
                  
                }}
                getPopupContainer={() => containerRef.current}
              />
          
          )}
        </Form.Item>
        <Form.Item name="client_name" style={{ display: "none" }}>
          <Input type="hidden" />
        </Form.Item>

        {/* <Button
          type="primary"
          onClick={() => {
            history.push("/add-new-client");
          }}
        >
          Add New
        </Button> */}
      </div>
      <div ref={containerRef}>
        <Form.Item
          label={
            <span style={labelStyle}>
              Competency <Required />
            </span>
          }
          name="client_competency"
          rules={[{ required: true, message: "Please select competency!" }]}
        >
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="value"
            // mode="multiple"
            // disabled={competencyQCData?.length == 0}
            options={competencyQCData}
            fieldNames={{
              label: "value",
              value: "value",
            }}
            onChange={() => {
              servicelinefetchData();
            }}
            getPopupContainer={() => containerRef.current}
            disabled={disabled}
          />
        </Form.Item>
      </div>

      <div ref={containerRef}>
        <Form.Item
          label={
            <span style={labelStyle}>
              Service Line <Required />
            </span>
          }
          name="service_line"
          rules={[{ required: true, message: "Please select service line!" }]}
        >
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="value"
            // mode="multiple"
            disabled={
              servicelineData?.length == 0 ||
              form.getFieldValue("competencyQC")?.length == 0 ||
              disabled
            }
            options={servicelineData}
            fieldNames={{
              label: "value",
              value: "value",
            }}
            getPopupContainer={() => containerRef.current}
          />
        </Form.Item>
      </div>
      <div ref={containerRef}>
        <Form.Item
          label={<span style={labelStyle}>Client FY Month</span>}
          name="client_fy_month"
        >
          <Select
            placeholder="Select"
            options={MONTH_LIST}
            allowClear={true}
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            // onChange={() => {
            //   form.setFieldValue("service_line", null);
            // }}
            getPopupContainer={() => containerRef.current}
            disabled={disabled}
          />
        </Form.Item>
      </div>

      <Form.Item
        label={
          <span style={labelStyle}>
            LOE Ref No. <Required />
          </span>
        }
        name="loe_ref_no"
        rules={[{ required: true, message: "Please Enter LOE Ref No" }]}
      >
        <Input
          placeholder="Enter LOE Ref No"
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
      </Form.Item>

      <Spin spinning={isDocumentLoading}>
        <Form.Item
          label={
            <span style={labelStyle}>
              LOE Document Upload <Required />
            </span>
          }
          name="loe_document_upload"
          rules={[{ required: true, message: "Please upload LOE document!" }]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(file) => handleChange(file, "LOE")}
            maxCount={1}
            multiple={false}
            accept="application/msword, application/pdf, .doc, .docx, .pdf"
          >
            <Button
              style={{
                width: "100%",
              }}
              icon={<UploadOutlined />}
              disabled={disabled}
            >
              Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label={
            <span style={labelStyle}>
              COI Document Upload <Required />
            </span>
          }
          name="coi_document_upload"
          rules={[{ required: true, message: "Please upload COI document!" }]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          multiple={false}
        >
          <Upload
            beforeUpload={() => false}
            onChange={(file) => handleChange(file, "COI")}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            maxCount={1}
            multiple={false}
            accept="application/msword, application/pdf, .doc, .docx, .pdf"
          >
            <Button
              style={{
                width: "100%",
              }}
              icon={<UploadOutlined />}
              disabled={disabled}
            >
              Upload
            </Button>
          </Upload>
        </Form.Item>
      </Spin>
    </Card>
  );
};
const mapStateToProps = (state) => ({
  levelList: getlevelList(state),
});

export default connect(mapStateToProps, null)(ClientFields);
