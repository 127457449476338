import moment from "moment";

export const addDaysinDate = (date, numberOfDays) =>
  moment(date).add(numberOfDays, "days").format("YYYY-MM-DD");

export const addMonthinDate = (date, numberOfMonths) =>
  moment(date).add(numberOfMonths, "months").format("YYYY-MM-DD");

export const dateInDetail = (date) => {
  const dateObj = moment(date || new Date());
  return {
    fullDate: date || "",
    date: dateObj.format("DD") || "",
    date_th: dateObj.format("Do") || "",
    month: dateObj.format("MM") || "",
    year: dateObj.format("YYYY") || "",
    day: dateObj.format("dddd") || "",
    shortDay: dateObj.format("ddd") || "",
    monthName: dateObj.format("MMMM") || "",
    shortMonthName: dateObj.format("MMM") || "",
    time: dateObj.format("hh:mm") || "",
    time24: dateObj.format("HH:mm") || "",
    meridiem: dateObj.format("a") || "",
    longDay: dateObj.format("dddd") || "",
    day_index: dateObj.day(),
  };
};

export const momentDate = (date) =>
  date !== "" ? moment(date, "YYYY-MM-DD") : "";

export const getMySqlDate = (date) =>
  date !== "" ? moment(date).format("YYYY-MM-DD") : "";

export const getServerDateToMySql = (date) =>
  date !== "" ? moment(date, "M/DD/YYYY, HH:mm:ss a").format() : "";

export const formatShortDate = (date) => {
  const dateObj = moment(date);
  return `${dateObj.format("Do")} ${dateObj.format("MMMM")}, ${dateObj.format(
    "ddd"
  )}`;
};

export const getinitialdate = () => {
  return moment("01-01-2022").format("DD-MM-YYYY");
};

export const getKenyanDateTime = () => {
  const d = new Date();
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  const nd = new Date(utc + 3600000 * +3);
  return moment(nd.toLocaleString("en-US"), "M/DD/YYYY, HH:mm:ss a").format();
};

export const convertUTCtoKenyaTime = (date) => {
  const dt = moment(date);
  dt.add(3, "hours");
  return dt;
};

export const getFirstDate = (date) =>
  moment(date).startOf("month").format("YYYY-MM-DD");
export const getLastDate = (date) =>
  moment(date).endOf("month").format("YYYY-MM-DD");

export const getFirstDateOfWeek = (date) =>
  moment(date).startOf('isoWeek').format('YYYY-MM-DD');

// Get the last date (Sunday) of the week for a given date
export const getLastDateOfWeek = (date) =>
  moment(date).endOf('isoWeek').format('YYYY-MM-DD');

export const getFirstDateOfPreviousYear = (date) =>
  moment(date).subtract(1, "year").startOf("year").format("YYYY-MM-DD");
export const getFirstDateOfYear = (date) =>
  moment(date).startOf("year").format("YYYY-MM-DD");
export const getLastDateOfYear = (date) =>
  moment(date).endOf("year").format("YYYY-MM-DD");

export const getMonthDateRange = (year, month) => {
  var startDate = moment([year, month - 1]);
  var endDate = moment(startDate).endOf("month");
  return { startDateOfMonth: startDate, lastDateOfMonth: endDate };
};

export const isDateBetweenTwoDates = (compareDate, startDate, endDate) => {
  compareDate = moment(compareDate);
  startDate = moment(startDate);
  endDate = moment(endDate);
  return compareDate.isBetween(startDate, endDate, "days", "[]");
};

// Function to generate weekdays for the current week
export const generateWeekdays = () => {
  const days = [];
  for (let i = 1; i < 8; i++) {
    days.push(moment(moment().startOf("week")).add(i, "days"));
  }
  return days;
};

export const findWeekId = (start, end) => {
  let startOfWeek = moment(start).startOf("week");
  const endOfWeek = moment(end).endOf("week");
  const isAcrossTwoMonths = startOfWeek.month() !== endOfWeek.month();

  const yearMonth = startOfWeek.format("YYYYMMM").toUpperCase();
  const weekOfYear = startOfWeek.week();
  const weekOfYearFirstDayOfMonth = startOfWeek.startOf("month").week();
  const weekOfMonth = weekOfYear - weekOfYearFirstDayOfMonth + 1;

  const currentWeekString = `${
    isAcrossTwoMonths ? yearMonth : startOfWeek.format("YYYYMMM").toUpperCase()
  }W${weekOfMonth}`;
  return currentWeekString;
};

export const findWeekIdsInRange = (selectedDateRange) => {
  const [startDate, endDate] = selectedDateRange;
  const weekIds = [];

  let currentDate = moment(startDate).startOf("week");
  const endOfRange = moment(endDate).endOf("week");

  while (currentDate.isSameOrBefore(endOfRange, "day")) {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");

    const yearMonth = startOfWeek.format("YYYYMMM").toUpperCase();
    const weekOfYear = startOfWeek.week();
    const weekOfYearFirstDayOfMonth = startOfWeek.startOf("month").week();
    const weekOfMonth = weekOfYear - weekOfYearFirstDayOfMonth + 1;

    const weekId = `${
      startOfWeek.month() !== endOfWeek.month()
        ? yearMonth
        : startOfWeek.format("YYYYMMM").toUpperCase()
    }W${weekOfMonth}`;
    weekIds.push(weekId);
    currentDate.add(1, "week");
  }

  return weekIds;
};

export const findWeeksInRange = (startDate, endDate) => {
  const weeks = [];

  let currentDate = moment(startDate).startOf("week");
  const endOfRange = moment(endDate).endOf("week");

  while (currentDate.isSameOrBefore(endOfRange, "day")) {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");

    const yearMonth = startOfWeek.format("YYYYMMM").toUpperCase();
    const weekOfYear = startOfWeek.week();
    const weekOfYearFirstDayOfMonth = startOfWeek
      .clone()
      .startOf("month")
      .week();
    const weekOfMonth = weekOfYear - weekOfYearFirstDayOfMonth + 1;

    const weekId = `${yearMonth}W${weekOfMonth}`;

    weeks.push({
      weekId: weekId,
      bookedStartDate: startOfWeek.format("YYYY-MM-DD"),
      bookedEndDate: endOfWeek.format("YYYY-MM-DD"),
    });

    currentDate.add(1, "week");
  }
  return weeks;
};

export const generateSchedule = (startDate, endDate) => {
  const schedule = [];
  let currentDate = moment(startDate);
  const end = moment(endDate);

  while (currentDate.isSameOrBefore(end, "day")) {
    const dayOfWeek = currentDate.day();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      const dateString = currentDate.format("MMM DD");
      const fullDateFormatted = currentDate.format("YYYY-MM-DD");
      schedule.push({
        date: dateString,
        available: true,
        fullDate: moment(fullDateFormatted),
      });
    }
    currentDate.add(1, "day");
  }

  return schedule;
};

export const differenceBetweenTwoTime = (startDate, endDate) => {
  const sDate = moment(startDate, "HH:mm");
  const eDate = moment(endDate, "HH:mm");
  return eDate.diff(sDate, "minutes");
};

export const generateHourMinutesFromMinutes = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins - 60 * hours;
  return `${hours <= 9 ? "0" : ""}${hours}:${
    minutes <= 9 ? "0" : ""
  }${minutes}`;
};

export const differenceBetweenTwoDates = (startDate, endDate) => {
  const sDate = moment(startDate);
  const eDate = moment(endDate);
  return sDate.diff(eDate, "days");
};

export const numberOfDaysBetweenDates = (start_date, end_date) => {
  const startDate = moment(start_date);
  const endDate = moment(end_date);
  return endDate.diff(startDate, "days");
};
