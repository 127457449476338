import React, {useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { Tabs } from "antd";
import { Spin, Modal, message } from "antd";
import {
  getFirstDateOfWeek,
  getLastDateOfWeek,
  getMySqlDate,
} from "utils/Date";
import LeaveForm from "organisms/LeaveForm";
import { CALL_API } from "common/API";
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getUserRoleId,
  getSelectedDepartmentId,
  getUserInfo,
  getClientid,
  getMenuList,
  getLeaveCategoryList,
  getUserList,
  getRoleList,
} from "redux/selectors";
import {
  get,
  includes,
  some,
} from "lodash";
import { getAllSubordinates, STATUS_CODE } from "common/Constants";
// import { CalendarContext } from ".";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import StaffLeaveIndicatorInCalendar from "pages/Calendar/StaffLeaveIndicatorInCalendar";

function LeaveWeekCalendar({
  userRoleId,
  userInfo,
  ClientId,
  menuList,
  userList,
  setTabId,
  tabId,
}) {
  const { TabPane } = Tabs;
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  let tabMenus = Menulist.filter(
    (item) =>
      item.key === "mycalendar" ||
      item.key === "teamcalendar" ||
      item.key === "allcalendar"
  );

  let newArray = [];
  newArray.push(...tabMenus);

  const items =
    newArray &&
    newArray
      ?.filter((data) => data.access === true)
      .map((data, index) => ({
        id: data.subtitle,
        label: data.subtitle,
        component: <></>,
      }));

  const [activeTab, setActiveTab] = useState("Mine");

  const [displayLeaveModal, toggleLeaveModal] = useState(false);

  const [leaveDetails, setLeaveDetails] = useState({});
  const [showMyTeamLeaves, toggleMyTeamLeave] = useState(false);

  const [payload, setPayload] = useState({
    status_id: "4,5",
    officesupervisorid: [""],
    selected_role_id: "0",
    start_date: getFirstDateOfWeek(moment()),
    end_date: getLastDateOfWeek(moment()),
    show_my_team_leave: true,
  });
  const [endDate, setEndDate] = useState("");

  moment.updateLocale("en", {
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
  });
  const filteruser = userList?.filter(
    (item) => item?.userid === userInfo.userid
  );

  const subordinates = getAllSubordinates(userList, filteruser[0]?.employeeid);

  const handleTabSelect = (key) => {
    const activeTab = items.find((item) => item.id === key);
    if (activeTab) {
      setTabId(activeTab.label);
    }

    if (key === 0) {
      toggleMyTeamLeave(!showMyTeamLeaves);
    }
  };

  const calendarRef = useRef(null);

  const queryString = new URLSearchParams(payload).toString();

  const { key, url } = queryKeys.getLeaveList(ClientId, queryString);

  const {
    data: leaveDataRes,
    isLoading: displayLoader,
    refetch: getLeaveList,
  } = useFetch(
    key,
    url,
    {
      enabled: !!ClientId,
    },
    payload
  );

  const MyleaveList = leaveDataRes?.response?.leaves?.filter(
    (val) => val?.userid === get(userInfo, "userid", "")
  );

  const myTeamLeaveList = leaveDataRes?.response?.teamLeaves?.filter((val) => {
    return subordinates.includes(val?.officesupervisorid);
  });


  const allLeaveList = leaveDataRes?.response?.leaves.filter((val) => {
    if (filteruser[0].OfficeSupervisorId === "none") {
      return val;
    } else {
      const findUser = userList?.filter(
        (item) => item?.employeeid === val.employeeid
      );
      const isSameCountry = some(findUser[0]?.managingoffice, (value) =>
        includes(filteruser[0]?.managingoffice, value)
      );
      return isSameCountry;
    }
  });

  const addLeave = (event, cellDate) => {
    if (userRoleId != 1) {
      const isPastDate =
        moment(getMySqlDate(cellDate)) < moment(getMySqlDate("2022-08-01"));
      if (!isPastDate) {
        toggleLeaveModal(true);
        setLeaveDetails({
          start_date: cellDate,
          end_date: cellDate,
        });
      }
    }
  };


 

  const cancelLeaveRequest = (details) => {
    const {
      user_leave_id,
      start_date: leave_start_date,
      end_date: leave_end_date,
      status_id,
    } = details;
    if (userRoleId !== 1 && status_id !== "4") {
      Modal.confirm({
        icon: "",
        content: "Are you sure you want to cancel your leave application?",
        okText: "YES",
        cancelText: "No",
        onOk: () => {
          return new Promise(async (resolve) => {
            const { code } = await CALL_API(
              `approve-reject-cancel-leave/${ClientId}`,
              "post",
              {
                user_leave_id,
                status_id: "9",
                is_cancel_request: false,
                start_date: getMySqlDate(leave_start_date),
                end_date: getMySqlDate(leave_end_date),
              }
            );
            if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
              message.error(`Oops!! something went wrong.`);
            } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
              message.error(`Invalid payload. Please enter correct data.`);
            } else if (code === STATUS_CODE.RECORD_EXIST) {
              message.error(`Leave aleady exist.`);
            } else if (code === STATUS_CODE.SUCCESS) {
              message.success(`Your leave application has been cancelled.`);
              getLeaveList();
            }
            resolve();
          }).catch((err) => console.log("[cancelLeaveRequest] Error -->", err));
        },
      });
    } else {
      setLeaveDetails(details);
      toggleLeaveModal(true);
    }
  };

  const cancelLeave = (details) => {
    const {
      user_leave_id,
      start_date: leave_start_date,
      end_date: leave_end_date,
      status_id,
    } = details;
    if (userRoleId !== 1 && status_id === "4") {
      Modal.confirm({
        icon: "",
        content: "Are you sure you want to cancel your leave application?",
        okText: "YES",
        cancelText: "No",
        onOk: () => {
          return new Promise(async (resolve) => {
            const { code } = await CALL_API(
              `approve-reject-cancel-leave/${ClientId}`,
              "post",
              {
                user_leave_id,
                status_id: "9",
                is_cancel_request: false,
                start_date: getMySqlDate(leave_start_date),
                end_date: getMySqlDate(leave_end_date),
              }
            );
            if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
              message.error(`Oops!! something went wrong.`);
            } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
              message.error(`Invalid payload. Please enter correct data.`);
            } else if (code === STATUS_CODE.RECORD_EXIST) {
              message.error(`Leave aleady exist.`);
            } else if (code === STATUS_CODE.SUCCESS) {
              message.success(`Your leave application has been cancelled.`);
              getLeaveList();
            }
            resolve();
          }).catch((err) => console.log("[cancelLeave] Error-->", err));
        },
      });
    } else {
      setLeaveDetails(details);
      toggleLeaveModal(true);
    }
  };

  const events = useMemo(() => {
    let leaveList = [];
    switch (activeTab) {
      case "Mine":
        leaveList = MyleaveList;
        break;
      case "My Team":
        leaveList = myTeamLeaveList;
        break;
      case "All":
        leaveList = allLeaveList;
        break;
      default:
        leaveList = MyleaveList;
    }

    return leaveList?.map((event) => {
      const startDateTime = moment(event.start_date)
        .set({ hour: 8, minute: 0 })
        .toISOString();
      const endDateTime = moment(event.end_date)
        .set({ hour: 17, minute: 0 })
        .toISOString();

      return {
        title: `${event.fullname} | ${event.leave_category_name}`,
        start: startDateTime,
        end: endDateTime,
        status: event.status_id,
        ...event,
      };
    });
  }, [activeTab, MyleaveList, myTeamLeaveList, allLeaveList]);

  const getEventColor = (statusId) => {
    switch (parseInt(statusId)) {
      case 4:
        return "#FBE2CD"; // Orange
      case 5:
        return "#E2FBCD"; // Light Blue
      case 6:
        return "#FFCCCB"; // Light Red
      default:
        return "#ADD8E6"; // Default to white
    }
  };

  const getEventBorderColor = (statusId) => {
    switch (parseInt(statusId)) {
      case 4:
        return "#F18D38";
      case 6:
        return "#38CDF1";
      case 5:
        return "#78F138";
      default:
        return "#F18D38";
    }
  };

  const textStyle = {
    whiteSpace: "nowrap", // Prevent wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Show ellipsis for overflow
  };

  const handleDatesSet = (arg) => {
    const startOfWeek = arg.start; // Start of the week (Date object)
    const endOfWeek = arg.end; // End of the week (Date object)

    console.log(`Start of the week: ${startOfWeek}`);
    console.log(`End of the week: ${endOfWeek}`);

    // You can format these dates as needed
    const formattedStart = startOfWeek.toISOString().split("T")[0];
    const formattedEnd = endOfWeek.toISOString().split("T")[0];

    setPayload({
      status_id: "4,5",
      officesupervisorid: [""],
      selected_role_id: "0",
      start_date: moment(formattedStart).format("YYYY-MM-DD"),
      end_date: moment(formattedEnd).format("YYYY-MM-DD"),
      show_my_team_leave: true,
    });

    // You can store these formatted dates in state or use them as needed
    console.log(`Formatted Start: ${formattedStart}`);
    console.log(`Formatted End: ${formattedEnd}`);
  };

  return (
    <>
      <Spin spinning={displayLoader}>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          height="auto"
          dateClick={(info) => addLeave(info.event, info.date)}
          contentHeight="auto"
          initialView="timeGridWeek" // Set the initial view to week
          events={events} // Use your events array
          headerToolbar={{
            left: "prev,next", // Navigation buttons
            center: "title", // Title in the center
            right: "timeGridWeek,dayGridMonth", // Week and Month views on the right
          }}
          datesSet={handleDatesSet}
          editable={false}
          selectable={true}
          allDaySlot={false} // Disable the all-day slot
          eventOverlap={false} // Allow events to overlap
          slotEventOverlap={true} // Prevent events from stacking in the same slot
          eventLimit={3} // Limit the number of events shown in a slot
          eventLimitClick={"popover"}
          eventContent={(info) => {
            const backgroundColor = getEventColor(
              info.event.extendedProps.status_id
            );
            const borderColor = getEventBorderColor(
              info.event.extendedProps.status_id
            );

            const startTime = info.event.start
              ? moment(info.event.start).format("hh:mm A")
              : "N/A";
            const endTime = info.event.end
              ? moment(info.event.end).format("hh:mm A")
              : "N/A";

            return (
              <StaffLeaveIndicatorInCalendar
                icon="team"
                leaveDetails={{ info, ...info.event.extendedProps }}
                isStaff={true}
                openEditLeaveModal={(details) => {
                  cancelLeaveRequest(details);
                }}
                EditLeaveModal={(details) => {
                  cancelLeave(details);
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    background: backgroundColor,
                    border: "none",
                    outline: "none",
                    color: "#000",
                    border: `1px solid ${borderColor}`,
                    height: "100%",
                    minWidth: "auto",
                    width: "100%", // Full width
                    boxSizing: "border-box", // Ensure padding is included in width
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <>
                    <span style={textStyle}>{info.event.title}</span>

                    <p style={textStyle}>
                      {startTime} - {endTime}
                    </p>
                  </>
                </div>
              </StaffLeaveIndicatorInCalendar>
            );
          }}
        />
      </Spin>

      <LeaveForm
        isModalVisible={displayLeaveModal}
        closeModal={() => toggleLeaveModal(false)}
        leaveDetails={leaveDetails}
        getLeaveList={getLeaveList}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  roleList: getRoleList(state),
});

export default connect(mapStateToProps, null)(LeaveWeekCalendar);
