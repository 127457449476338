import { Col, Form, Row } from "antd";
import Table from "atoms/Table";
import Title from "atoms/Title";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";
import queryKeys from "common/queryKey";
import { useScrollFetch } from "hooks/useScrollFetch";
import { uniqBy } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setFilter } from "redux/actions";
import {
  getClientid,
  getFilters,
  getManagingOffice,
  getUserList,
} from "redux/selectors";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { utils, writeFileXLSX } from "xlsx";

function OnboardingReport() {
  const userList = useSelector((state) => getUserList(state));
  const [payload, setPayload] = useState({});
  const clientId = useSelector(getClientid);
  const managingOfficeList = useSelector(getManagingOffice);
  const history = useHistory();
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
      className: "normal-column ",
      render: (val) => {
        return <span>{val}</span>;
      },
    },
    {
      title: "Email Id",
      dataIndex: "userid",
      key: "userid",
      className: "normal-column",
      render: (val, record) => {
        return <span>{val ?? "-"}</span>;
      },
    },

    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      className: "normal-column",
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Submission Status",
      dataIndex: "onBoardingFormStatus",
      key: "onBoardingFormStatus",
      className: "normal-column",
      render: (val, record) => {
        return <span>{val === "0" ? "Yes" : "No"}</span>;
      },
    },
    {
      title: "Country",
      dataIndex: "managingoffice",
      key: "managingoffice",
      className: "normal-column",
      render: (val, record) => {
        return <span>{val?.[0]}</span>;
      },
    },
    {
      title: "Date Submitted",
      dataIndex: "onboardingdate",
      key: "onboardingdate",
      className: "normal-column",
      render: (val) => (val ? moment(val).format("DD-MM-YYYY") : "-"),
    },
  ];
  const [form] = Form.useForm();

  const filters = useSelector(getFilters);
  const menuName = "timesheetReport";
  const initialFilter = filters[menuName];

  const dispatch = useDispatch();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "name", values.name || []));
    dispatch(
      setFilter(menuName, "managingoffice", values.managingoffice || [])
    );
    dispatch(setFilter(menuName, "status", values.status || []));
    // const name = form.getFieldValue("name") || [];
    // const managingoffice = form.getFieldValue("managingoffice") || [];
    // const status = form.getFieldValue("status") || [];

    // setPayload({
    //   userid: name,
    //   managingoffice: managingoffice,
    //   IndependenceForm: status,
    // });
  };

  // const getFilteredData = () => {
  //   const name = form.getFieldValue("name") || [];
  //   const managingoffice = form.getFieldValue("managingoffice") || [];
  //   const status = form.getFieldValue("status") || [];

  //   setPayload({
  //     userid: name,
  //     managingoffice: managingoffice,
  //     IndependenceForm: status
  //   })

  // };

  const { getOnboardingReport } = queryKeys;

  const { key, url } = getOnboardingReport(
    clientId,
    filters?.alldeclaration?.name ?? []
  );

  const {
    data,
    lastElementRef,
    isLoading,
    refetch,
    isReloading,
    fetchNextPage,
  } = useScrollFetch(key, {
    endPoint: url,
    queryParam: "LastEvaluatedKey",
    extra: {
      clientId: clientId,
    },
  });

  const dataList = useMemo(() => {
    if (data) {
      return data?.pages?.reduce((acc, page) => {
        if (page?.response === undefined) return acc;
        return [...acc, ...page?.response];
      }, []);
    } else {
      return [];
    }
  }, [data]);

  const userOptions = dataList?.map((user) => {
    return {
      label: user?.fullname,
      value: user?.userid,
    };
  });

  const managingOfficeOption = dataList?.map((val) => {
    return {
      label: val.country,
      value: val.country,
    };
  });

  const statusOptions = [
    {
      label: "Submitted",
      value: "yes",
    },
    {
      label: "Not Submitted",
      value: "no",
    },
  ];

  const getFilteredData = () => {
    const name = form.getFieldValue("name") || [];

    const country = form.getFieldValue("managingoffice") || [];
    const status = form.getFieldValue("status") || [];

    if (name.length > 0 || status.length > 0 || country.length > 0) {
      return dataList?.filter((item) => {
        const nameMatch =
          name && name.length > 0 ? name.includes(item.userid) : true;

        const countryMatch =
          country && country.length > 0
            ? country.includes(item?.managingoffice?.[0]) // Check if any managing office matches
            : true;

        return nameMatch && countryMatch;
      });
    } else {
      return dataList;
    }
  };

  const xport = React.useCallback(() => {
    const elt = document.getElementById("Table");
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "Onboarding Report.csv");
  });

  useEffect(() => {
    if (dataList?.length < 20 && data?.pages[0]?.LastEvaluatedKey !== null) {
      fetchNextPage();
    }
  }, [dataList]);
  return (
    <div>
      <Row>
        <Col sm={24}>
          <div className="d-flex justify-content-between">
            <Title>Onboarding Report</Title>
            <ButtonOutlined className="text-end" onClick={xport} type="link">
              Export CSV file
            </ButtonOutlined>
          </div>
        </Col>
        <Col sm={24}>
          <Form
            form={form}
            layout="vertical"
            className="mt-2"
            onValuesChange={handleFormChange}
            initialValues={{ ...initialFilter }}
          >
            <Row gutter={12} className="g-3 mb-2">
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="name"
                  label="Name"
                  options={userOptions}
                  form={form}
                  handleFormChange={handleFormChange}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="managingoffice"
                  label="Country"
                  options={uniqBy(managingOfficeOption, "label")}
                  form={form}
                  handleFormChange={handleFormChange}
                />
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={6} xl={5}>
                <DropdownCheckBox
                  name="status"
                  label="Status"
                  options={statusOptions}
                  form={form}
                />
              </Col> */}
            </Row>
          </Form>
        </Col>
      </Row>
      <Table
        dataSource={getFilteredData() ?? []}
        columns={columns}
        pagination={false}
        loading={isLoading}
        onRow={(record, index) => ({
          ref: index === dataList?.length - 20 ? lastElementRef : null,
        })}
        id="Table"
      />
    </div>
  );
}

export default OnboardingReport;
