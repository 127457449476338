import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Modal, Button } from "antd";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import Table from "atoms/Table";
import {
  getUserRoleId,
  getClientid,
  getLeaveCategoryList,
  getMenuList,
  getallLeaveList,
  getallLocationList,
  getUserList,
  getUserInfo,
} from "redux/selectors";
import { connect, useSelector } from "react-redux";
import Confirm from "../../src/assets/Confirm.svg";
import { STATUS_CODE } from "common/Constants";
import AddLeaves from "organisms/AddLeaves";
import EditLeaves from "organisms/EditLeaves";
import { bindActionCreators } from "redux";
import { setAllLeaveList } from "redux/actions";
import { isEmpty } from "lodash";

const StyledButton = styled(Button)`
  width: 150px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;
const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const LeaveCategories = ({ ClientId, menuList, userRoleId, saveAllLeave, allLeaveList }) => {
  const columns = [
    {
      title: "Leave Category Name",
      dataIndex: "leaveCategoryName",
      key: "leaveCategoryName",
      width: "15%",
    },
    {
      title: "No of Days",
      dataIndex: "numberOfLeaves",
      key: "numberOfLeaves",
      width: "15%",
    },
    {
      title: "No of Department",
      dataIndex: "noOfDepartmentId",
      key: "noOfDepartmentId",
      width: "15%",
    },
    {
      title: "No of Location",
      dataIndex: "noOfLocationId",
      key: "noOfLocationId",
      width: "15%",
    },
    {
      title: "Allocation",
      dataIndex: "allocation",
      key: "allocation",
      width: "15%",
    },
    {
      title: "Eligibility",
      dataIndex: "eligible",
      key: "eligible",
      width: "15%",
    },
  ];

  const [displayAddLeaveModal, toggleAddLeaveModal] = useState(false);
  const [displayEditLeaveModal, toggleEditLeaveModal] = useState(false);
  const [displayLeaveLoader, setDisplayLeaveLoader] = useState(false);
  const [LeaveList, setLeaveList] = useState(allLeaveList);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [deactivateLeave, toggledeactivateLeave] = useState(false);
  const [confirmdeactivateLeave, toggleconfirmdeactivateLeave] =
    useState(false);
  const [leaveid, setLeaveid] = useState();
  const [btnLoader, togglebtnLoader] = useState(false);
  const userInfo = useSelector(getUserInfo)
  const userList = useSelector(getUserList)
  const filterUser = userList?.find((val) => val?.userid === userInfo?.userid)
  const locations = useSelector(getallLocationList)
  const filtertedBasedonCountry = locations?.filter((val) => filterUser?.managingoffice.includes(val?.country))
  const filterLocations = filtertedBasedonCountry?.map((val) => {
    return val?.location_id
  })

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const ViewLeave = (LeaveObject, isViewOnly = false) => {
    toggleEditLeaveModal(true);
    setLeaveid(LeaveObject.leaveCategoryId);
    setLeaveDetails({
      ...LeaveObject,
      isViewOnly,
    });
  };

  const deleteBreak = async (leaveid) => {
    togglebtnLoader(true);
    const { code } = await CALL_API(
      `owner-leave/${ClientId}/${leaveid}`,
      "delete",
      {
        statusid: "0",
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      togglebtnLoader(false);
      toggledeactivateLeave(false);
      toggleconfirmdeactivateLeave(true);
      toggleEditLeaveModal();
      getLeaveList();
    }
  };

  const deletedLeave = () => {
    toggledeactivateLeave(true);
  };

  const getLeaveList = async () => {
    try {
      setDisplayLeaveLoader(true);
      const { response } = await CALL_API(`owner-leave/${ClientId}`, "get", {});
      if(!isEmpty(response)){
        let filterLeaves = response?.filter((val) => 
          val?.locationId && filterLocations.some(location => val.locationId.includes(location))
        );
        
        saveAllLeave(filterLeaves);
        
      }
      setDisplayLeaveLoader(false);
    } catch (error) {
      console.log("[getDepartmentList], Error-->", error);
    }
  };

  useEffect(() => {
    getLeaveList();
  }, [setLeaveList]);

  useEffect(() => {
    setLeaveList(allLeaveList);
  }, [allLeaveList]);

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  return (
    <>
      {!displayAddLeaveModal && !displayEditLeaveModal && (
        <>
          <Row className="page-title">
            <Col sm={24} md={12} style={headerstyle}>
              List of Leaves
            </Col>
            {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "leave-categories" &&
                    subItem?.permission.create
                )
            ) && (
              <Col sm={24} md={12} className="align-right">
                <StyledButton
                  className="mx-0"
                  type="primary"
                  htmlType="submit"
                  onClick={() => toggleAddLeaveModal(true)}
                >
                  + Add Leaves
                </StyledButton>
              </Col>
            )}
          </Row>
          <Spin spinning={displayLeaveLoader && LeaveList?.length === 0}>
            <Table
              dataSource={LeaveList}
              columns={columns}
              rowKey="department_id"
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    toggleEditLeaveModal(true);
                    ViewLeave(record);
                  },
                };
              }}
            />
          </Spin>
        </>
      )}
      {displayAddLeaveModal && (
        <AddLeaves
          isModalVisible={displayAddLeaveModal}
          closeModal={() => toggleAddLeaveModal(false)}
          getLeaveList={getLeaveList}
        />
      )}
      {displayEditLeaveModal && (
        <EditLeaves
          isModalVisible={displayEditLeaveModal}
          closeModal={() => toggleEditLeaveModal(false)}
          getLeaveList={getLeaveList}
          LeaveDetails={leaveDetails}
          deletedLeave={deletedLeave}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "leave-categories" &&
                    subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}

      <StyleddeactivateModal
        title="Are you sure you want to deactivate this Leave?"
        visible={deactivateLeave}
        width={500}
        onCancel={() => toggledeactivateLeave(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head">
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateLeave(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteBreak(leaveid)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateLeave}
        width={500}
        onCancel={() => toggleconfirmdeactivateLeave(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Leave has been deactivated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
};
const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  menuList: getMenuList(state),
  allLeaveList: getallLeaveList(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAllLeave : setAllLeaveList
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps, null)(LeaveCategories);
